@import '../../../assets/scss/abstracts/abstracts';

.doughnut-summary {
    padding-top: 0px;
    min-height: 45px!important;
    font-family: $font-book;
    .placi-chart {
      min-height: 100px;
      h5 {
        margin-bottom: 5px;
      }
      .donut-val {
        p {
          margin-bottom: 2px;
        }
      }
    }

    .chart-main-wrapper {
        width: 70%;
        //margin-left: 25%;
       position: absolute;
       top: 25px;
       right: 0;
       .csn-chart {
        display: none;
       }
    }
}

.status-finalized{
    background-color: #559E49
}
.status-onhold{
    background-color: #FF5A4B;
}
.no-information{
    background-color: #F68D2E
}
.blue-status{
  background-color: #2495ff
}
.cancelled-status{
  background-color: #fde8d5
}


.dot {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10%;
    margin-top: 15px;
  }

  .chart-container{
    margin-top: -40px;
  }
  .flight-no-chart
  {
    color: rgb(0, 95, 150);
    font-weight: 600;
  }

.flight-date-subhead{
    p{
        font-size: 16px !important;
        font-family: "EtihadAltis-Text", sans-serif;
        margin-top: 16px;
        margin-left: 16px;
        color:$textcolor !important;
    }
    h3{
      font-size: 22px;
    }
  }

  .csn-flights-table{
    font-family: "EtihadAltis-Book" !important;
    tr{
    th{
        font-weight: 700 !important;
        color: $textcolor !important;
        white-space: pre-line;
        width: 48px;
        //padding: 0;
        margin-left: 16px;
        font-size: 16px;
    }
    th:nth-child(2){
      width: 94px;
    }
    th:nth-child(3){
      width: 93px;
    }
    th:nth-child(4){
      width: 62px;
    }
    th:nth-child(5){
      width: 42px;
    }
    th:nth-child(6){
      width: 48px;
    }
    th:nth-child(7){
      width: 68px;
    }
    .sign-post-container {
      max-width: 155px;
    }
  }

    td{
        font-family: "EtihadAltis-Book",sans-serif !important;
        font-size: 16px !important;
        font-weight: normal;
    }

    .extend-button {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background: none !important;
      padding: 0;
      margin: 0;
  }

  .expand-details{
    width: 180px;
  }
  
  .extend-button:focus,
  .extend-button:active {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
  }
  
    //font-weight: 600 !important;
  }

  .sort-icon {
    vertical-align: middle;
    &.pi {
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }
    &.pi-sort-alt {
      &:before {
        background: url('../../../assets/images/icon-sort.svg') no-repeat;
        margin-top: -5px;
      }
    }
    &.pi-sort-amount-up-alt {
      &:before {
        background: url('../../../assets/images/icon-sort-up.svg') no-repeat;
      }
    }
    &.pi-sort-amount-down {
      &:before {
        background: url('../../../assets/images/icon-sort-down.svg') no-repeat;
        margin-top: -7px;
      }
    }
    &.pi-angle-right {
      &:before {
        background: url('../../../assets/images/right-arrow.png') no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-top: -15px;
      }
    }
    &.pi-angle-down {
      &:before {
        background: url('../../../assets/images/down-arrow.png') no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-top: -15px;
      }
    }
  }