@import '../../assets/scss/abstracts/abstracts';

.form-row {
    &.alert {
        margin: 25px 0 25px 3px;
        padding: 0;
        background: transparent;      
        border: none;
        @media (min-width: 992px) {
            margin: 25px 0 25px 3px;
        }
        .col {
            padding: 0;
            margin: 0;
        }
        p {
            margin-bottom: 0;
            font-size: 20px;
            display: inline-block;
            margin-left: 10px;          
            color: #6B7280;
            font-family: $font-book;
        }
        .image-wrapper{
            margin-left: 1rem;
            width: 35px;
            padding-bottom: 4px;
            padding-right: 4px;
            position: absolute;
            margin-top: 2px;
        }
        .common-alert-icon-wrapper{
            display: inline-block;          
            padding: 25px 20px;           
            width: 32px;        
            margin-left: 2rem;
            background-position: center;
            background-size: cover;
        }

        .alert-success-wrapper{
            background-color: #E4EBCC !important;
            border-color: #E4EBCC !important;    
        }
        .alert-success-tick{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/Check-mark-filled.png') no-repeat;
        }        
        .alert-error-wrapper{
            background-color: #F5D5D6;              
        }
        .alert-error-close{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/Error-Close-Filled.png') no-repeat;
        }
        // .alert-close {
        //     display: inline-block;
        //     width: 79px;
        //     height: 79px;
        //     padding: 20px 20px;
        //     float: right;
        //     background: transparent;
        //     border-left: 1px solid #4E4C4A;
        //     background: url('../../assets/images/icon-close-alert.png') no-repeat;
        //     background-position: center;
        // }
         .alert-info-wrapper{
            background-color: #E8F1F4 !important;
            border-color: #E8F1F4 !important;    
        }  
        .alert-info-icon{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/notification-info.svg') no-repeat;
        } 
        .alert-warning-wrapper{
            background-color: #FDE8D5 !important;
            border-color: #FDE8D5 !important;
        }
        .alert-warning-icon{
            @extend .common-alert-icon-wrapper;
            background: url('../../assets/images/notification-warning.svg') no-repeat;
        }
    }
    .alert-inner-wrapper{
        display: grid;
        grid-template-columns: 5% 70% 25%;
        padding: 22px 22px 22px 0px;
        background: #F9FAFB;
        border-radius: 8px;
        box-shadow: 0px 1px 11px #00000029;
        
    }
    @media (max-width: 600px) {
        .alert-inner-wrapper {
          grid-template-columns: 15% 60% 25%;
        }
      }
    .dismiss-button{
        background: transparent;
        border:none;
        font-weight: 400;
        font-size: 18px;
        display: flex;
        color: $link;
        align-items: flex-end;
        justify-content: flex-end;  
        &:hover{
            text-decoration: underline;
            cursor: pointer;
        }  
    }
  
}