@import '../../../assets/scss/abstracts/abstracts';
.schedule-visualization {
    .selected-week {
        font-size: 18px;
        font-family: $font-book;
    }
    .main-table-heading {
        margin: 0 auto;
        .col-6{
            border: 1px solid $border;
            border-bottom: none;
            padding-right: 20px;
            flex: 0 0 49.9999%;
            max-width: 49.9999%;
            &:last-child {
                border-left: none;
            }
        }
       
    }
    .flights-rotation { 
        font-family: "EtihadAltis-Book";
        font-size: 15px;
        margin: 0 auto;
        &.flight-header {
            font-weight: 700;
            border-top: 1px solid $border;
            top: 72px;
            background: $white;
            z-index: 1;
        }
        &:nth-of-type(odd) {
            background: #f7f7f7;
        }
        border-bottom: 1px solid $border;
        .flight-row {
            
        }
        .col-1, .col-2 {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .col-1:first-child {
            border-left: 1px solid $border;
        }
        .start-col {
            flex: 0 0 11.3333333333%;
            max-width: 11.3333333333%;
            .form-item__label:before {
                margin-left: 10px;
            }
            &.col-2 {
                flex: 0 0 22.6666666667%;
                max-width: 22.6666666667%;
            }
            &.col-flight-date {
                flex: 0 0 24.6666666667%;
                max-width: 24.6666666667%;
            }
            
        }
        .end-col {
            border-right: 1px solid $border;
            flex: 0 0 7.3333333333%;
            max-width: 7.3333333333%;
            &.col-2 {
                flex: 0 0 13.3333333333%;
                max-width: 13.3333333333%;
            }
        }
        .small-col {
            flex: 0 0 7.3333333333%;
            max-width: 7.3333333333%;
            &.col-2 {
                flex: 0 0 14.3333333333%;
                max-width: 14.3333333333%;
            }
        }
    }
    .float-menu {
        bottom: 24px;
        width: 70px;
        position: fixed;
        right: 30px;
        .button-row {
            background-color: #f2f2f2f2;
            opacity: 0.7;
            padding: 10px 30px;
            max-width: 55px;
            border-radius: 10px;
            .swap-btn {
                background-image: url('../../../assets/images/icon-swap.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 30px;
                height: 30px;
                padding: 10px 0;
                margin: 10px 0;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            .scroll-btn {
                background-image: url('../../../assets/images/icon-up-arrow.png');
                background-repeat: no-repeat;
                background-size: contain;
                width: 18px;
                height: 15px;
                max-width: 18px;
                padding: 10px 0;
                margin: 10px 0 10px 4px;
                cursor: pointer;
                &.down {
                    background-image: url('../../../assets/images/icon-down-arrow.png');
                }
                &:focus {
                    outline: none!important;
                }
            }
        }        
    }
}
