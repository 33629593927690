@import '../../assets/scss/abstracts/abstracts';


.permanent-booking-wrapper {
    background-color: #fff;
    margin-top: 28px;

    @media (min-width: 768px) {
        padding: 0px 0px 35px;
    }

    .page-heading {
        font-size: 24PX;
        margin: 0;
    }

    .form-item__field {
        margin: 0 5px;
    }

    .no-border {
        border: none;
    }

    .form-item__field-checkbox {
        background-color: $green;
    }

    .permnt-booking-btn-wrpr {

        a {
            width: 45% !important;
            white-space: nowrap;

            img {
                height: 38px;
            }

            margin: 5px 8px;
            outline: none;

        }
    }

    .permanent-booking-file-select-dropdown {
        margin: 20px;
        min-width: 260px;
        max-width: 260px;
        margin-left: -3px;
    }

    .permanent-booking-list-header {
        &__container {
            margin-top: 20px;
        }



        &__flex {
            margin-left: 0px !important;
            display: content !important;
            height: 48px;

            tr {
                padding: 0px;
                margin: 0px;
            }

            th {
                width: 12% !important;
                padding-top: 10px;
                padding-bottom: 0px;
                padding-left: 12px;
                //flex: 1 1 auto;
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                margin-top: "5px";
                margin-bottom: 0 !important;
            }

            th:nth-child(1) {
                width: 13% !important;
                padding-left: 0
            }

            th:nth-child(5) {
                width: 15% !important;
                padding-left: 0px;
            }

            th:nth-child(6) {
                width: 13% !important;
                padding-left: 0px;
            }

            th:nth-child(7) {
                width: 10% !important;
                padding-left: 0;
            }

            th:nth-child(8) {
                width: 11.8% !important;
                padding-left: 0;
            }

            td {
                font-size: 10px;
            }

            p {
                flex: 1 1 auto;
                font-size: 16px;
                font-weight: 600;
                text-align: left;
            }

            &__shipping-item {
                flex: 2 1 auto;
            }
        }
    }

    .borderRadius-L0 .p-dropdown {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        width: 60px !important;
    }

    .permanent-booking-list-filter {
        color: $link;
        font-weight: normal;
        text-decoration: underline;
    }

    .permanent-booking-view-edit {
        color: $link;
        font-weight: normal;
    }


    .permanent-booking-list-item {

        //margin-bottom: 0px !important;
        &__container {
            //margin: 10px;
            border: 1px solid $border;
            border-radius: 4px;
            padding: 10px 10px;
        }

        &__list {
            padding: 0px !important;
            margin-left: 0px !important;

            .row {
                display: block !important;
            }

            th {
                width: 12% !important;
                line-height: 15px !important;
                height: 70px !important;
                text-align: left;
                padding-top: 25px !important;
                margin-bottom: 0 !important;
            }

            th:nth-child(1) {
                width: 14% !important;
                padding: 0
            }

            th:nth-child(2) {
                width: 12% !important;
                padding: 0
            }

            th:nth-child(3) {
                width: 12% !important;
                padding: 0
            }

            th:nth-child(4) {
                width: 10% !important;
                padding-left: 0px;
            }

            th:nth-child(5) {
                width: 16% !important;
                padding-left: 13px
            }

            th:nth-child(6) {
                width: 13% !important;
                padding-left: 0;
            }

            th:nth-child(7) {
                width: 11% !important;
                padding: 0;
            }

            th:nth-child(8) {
                width: 10.8% !important;
                padding-left: 25px !important;
                //text-align: center;
            }
        }

        &__flex {
            display: flex;
            padding: 10px 0px;

            p {
                padding: 0 5px;
                flex: 1 1 auto;
                font-size: 16px;
            }

            &__shipping-item {
                flex: 2 1 auto;
            }
        }

        &__expanded-container {
            padding: 5px;
        }
    }

    .pmt-booking-sub-header {
        font-size: 15px;
        font-weight: 600;

        &__wrapper {
            display: flex;
            max-width: 380px;

            p {
                flex: 1 1 auto;
            }
        }
    }

    .pmt-booking-sub-row {
        &__wrapper {
            display: flex;
            max-width: 380px;

            p {
                flex: 1 1 auto;
            }
        }
    }

    .row {
        .form-group {
            .excel_Upload_button {
                text-decoration: none;
                margin-left: -10px;
                width: auto;
                font-weight: 300;
                border-radius: 0px;
                margin-top: -5px;


                .downld_temp {
                    color: rgb(0, 95, 150);
                    font-family: EtihadAltis-Book;
                    font-size: 16px;
                    height: 15px;
                    letter-spacing: 0px;
                    line-height: 22.4px;
                    width: 141px;
                    font-weight: 300;
                    border-radius: 0px;
                    margin-left: 4px;
                }

                img {
                    height: 23px;
                }
            }
        }
    }
}

.permanent-booking-list-overlay-panel {

    //left: 1100.55px !important;
    &__header {
        font-size: 16px;
        font-weight: 600;
    }
}

.permament-view-edit {
    .weight-div {
        .borderRadius-R0 {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }

        .borderRadius-L0.border-left0 {
            .form-control {
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-left: 0;
            }
        }
    }

    .form-control {
        height: 50px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        //border: 1px solid rgb(78, 76, 74);	
        color: rgb(78, 76, 74);
        font-family: EtihadAltis-Book;
        font-size: 20px;
        font-weight: 300;
        border-radius: 12px;
        box-shadow: none;
        transition: none;
    }

    label {
        color: rgb(78, 76, 74);
        font-size: 16px;
        font-family: EtihadAltis-Book;
        font-weight: 300;
    }
}

//   .cargo-portal-container .form-group{
//     padding-left: 0px;
//   }
//   .cargo-portal-container .table .p-datepicker-calendar th,	
//   .cargo-portal-container .table .p-datepicker-calendar td {	
//     border: 0;	
//   }	
//   .table-hover .p-datepicker-calendar tbody tr:hover {	
//     background-color: transparent;	
//   }	
//   .cargo-portal-container {	
//     .table ul {	
//         padding-left: 0;	
//     }	
//   }	
.btn-reprocess {
    .button {
        height: auto !important;
        text-align: center !important;
        //font-size: 16px !important;
        //width: 100%;
    }
}

.btn-reprocess-cancel {
    .button {
        margin-left: 20% !important;
        height: auto !important;
        text-align: center !important;
        //font-size: 16px !important;
       // width: 60%;
    }
}

.button-submit-row {
    margin-top: 48px !important;
    margin-bottom: 55px !important;
}

.pb-head {
    font-weight: 900;
    margin-bottom: 30px;
    margin-top: 40px;

    h2 {
        font-weight: 900;
        font-size: 28px;
    }
}

.pb-main {
    label {
        font-size: 14px !important;
        font-weight: bold !important;
        margin-top: 14px;
        margin-bottom: 12px !important;
    }

    span {
        font-weight: 900 !important;
        font-size: 18px !important;
    }
}

.pb-sub-head {

    // margin-top: 20px !important;
    // padding-top: 15px !important;
    h2 {
        font-weight: normal !important;
        font-size: 29px;
        margin-top: 50px;
    }

}

.pb-sub-head-custom {
    h2 {
        font-weight: normal !important;
        font-size: 29px;
    }

    label {
        font-size: 14px !important;
        font-weight: bold !important;
        margin-top: 15px !important;
    }

    span {
        font-weight: 900 !important;
        font-size: 18px !important;
    }
}

.pb-main-sub {
    //margin-top: 0px !important;
    margin-bottom: 50px !important;

    label {
        font-size: 16px !important;
        font-weight: 300 !important;
        color: rgb(78, 76, 74) !important;
        font-family: EtihadAltis-Book !important;
        height: 22px;
        margin-top: 15px !important;
        margin-bottom: 12px !important;
    }

    span {
        font-weight: 300 !important;
        font-size: 20px !important;
        height: 28px !important;
        color: rgb(78, 76, 74) !important;
        font-family: EtihadAltis-Book !important;
        letter-spacing: 0px;
        line-height: 28px;
    }

    p {
        font-weight: normal !important;
        font-size: 18px !important;
        margin-bottom: 0px !important;
    }

}

// .form-group label{
//         font-weight: 400 !important;
//         font-family: "EtihadAltis-Book", sans-serif !important;
//         padding-bottom: 10px;
// }

// .cargo-portal-container .col label{
//     font-weight: 400 !important;
// }
// .input-wrapper {
//     padding-right: 20px;
//     margin-top: 17px;
// }

// .search-panel .form-group label {
//     font-size: 15px;
//     margin-top: 20px;
//     margin-left: 3px;
// }

.custom-flight-inputs {
    margin-top: 14px;
}

/*  #smallheader{
    width: 448px;
    height: 134px;  */
/* font-size: 16px;
    //font-family: EtihadAltis-Book;
    margin-right: 50%;
    text-align: left;
    // margin-left: 50%;
    letter-spacing: normal;
    width: 60rem; */
.description {
    height: 157px;
    color: rgb(78, 76, 74);
    font-size: 16px;
    font-family: EtihadAltis-Book;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 22.4px;

    p.paragraph-1 {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    p.paragraph-2 {
        margin-bottom: 20px;
        margin-left: 15px;
    }
}


ul.list {
    list-style-type: disc;
    height: 134px;
    color: rgb(78, 76, 74);
    font-size: 16px;
    font-family: EtihadAltis-Book;
    letter-spacing: 0px;
    line-height: 22.4px;
    margin-left: 32px;
}

.filterUi {
    margin-left: 550px !important;
    padding-top: 0% !important;
}

.dropdownUi {
    margin-left: 16px !important;
    max-width: 50px !important;
    min-width: 50px !important;
}

.checkbox-color {
    label {
        padding-left: 12px;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:disabled {
            +label {
                color: $disabledFill;

                &:before {
                    border: 1px solid $disabledFill;
                    opacity: 1;
                }
            }
        }

        +label {
            position: relative;
            display: inline-block;
            padding: 0 0 0 30px;
            line-height: 18px;
            cursor: pointer;
            color: $textcolor;
            //font-family: $font-book !important;
            font-size: 15px;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:before {
                content: " ";
                border: 1px solid $textcolor;
                border-radius: 2px;
                width: 18px;
                height: 18px;
            }

            &:after {
                width: 6px;
                height: 10px;
                left: 6px;
                top: 2px;
                border-bottom: 2px solid #fff;
                border-right: 2px solid #fff;
                transform: scale(0);
                opacity: 0;
                content: '';
            }
        }

        &:checked+label {
            color: $textcolor;

            &:after {
                transform: scale(1) rotate(45deg);
                opacity: 1;
            }

            &:before {
                background-color: $etihadNewDarkBlue;
                border: none;

            }
        }
    }
}

.table {
    width: 173px;

    .checkbox-color {
        padding-left: 15px;
    }

    hr {
        width: 140px;
    }
}

.btn-print {
    .button {
        margin-left: 0 !important;
    }
}

.btn-back {
    .button {
        /* height: auto !important;	
            text-align: center !important;	
            font-size: 16px !important;	
            width: 60%;	
            margin-left: -15px; */
        border-radius: 4px;
        border: 1px solid rgb(78, 76, 74);
        height: 50px;
        width: 120px;
        margin-left: -15px;
        margin-top: 15px;
    }
}

// .f-15{
//     font-family: EtihadAltis-Book !important;
//     font-size: 16px !important;
//     color: rgb(78, 76, 74) !important;
//     font-weight: 300 !important;
//     height: 22px !important;
//     letter-spacing: 0px;
//     line-height: 22px;
// }

.f-16 {
    height: 28px !important;
    color: rgb(78, 76, 74) !important;
    font-size: 20px !important;
    font-family: EtihadAltis-Book !important;
    font-weight: 300 !important;
    letter-spacing: 0px;
    line-height: 28px;
}

.shipment {
    padding-left: 25px !important;
}

.awb {
    padding-left: 25px !important;
}

.status {
    padding-left: 60px !important;
}

.view {
    text-align: center !important;
}

#para {
    display: none;
}

.m1 {
    margin-top: 48px !important;
}

@media print {
    #para {
        display: inline;

        .heading {
            margin-left: 10px !important;
            font-size: 20px !important;
            font-weight: 300;
        }

        .filename {
            margin-left: 10px !important;
            margin-bottom: 2px !important;
        }
    }

    #header {
        width: 800px;
        margin-left: 10px !important;

        th:nth-child(1) {
            width: 14% !important;
            padding: 0
        }

        th:nth-child(2) {
            width: 12% !important;
            padding: 0;
            padding-top: 10px;
        }

        th:nth-child(3) {
            width: 16% !important;
            padding-left: 10px;
        }

        th:nth-child(4) {
            width: 10% !important;
            padding-left: 0px;
        }

        th:nth-child(5) {
            width: 20% !important;
            //padding-left: 2px;
        }

        th:nth-child(6) {
            width: 17% !important;
            padding-left: 0;
        }

        th:nth-child(7) {
            width: 11% !important;
            padding: 0;
            padding-top: 10px;
        }

        th:nth-child(8) {
            width: 0% !important;
            padding: 0;
        }
    }

    #body {
        width: 800px;
        margin-left: 10px !important;

        th:nth-child(1) {
            width: 14% !important;
            padding: 0;
            padding-top: 10px;
        }

        th:nth-child(2) {
            width: 12% !important;
            padding: 0;
            padding-top: 10px;
        }

        th:nth-child(3) {
            width: 16% !important;
            padding-left: 10px;
        }

        th:nth-child(4) {
            width: 10% !important;
            padding-left: 0px;
        }

        th:nth-child(5) {
            width: 20% !important;
            //padding-left: 15px;
            //padding-left: 45px !important;
        }

        th:nth-child(6) {
            width: 17% !important;
            padding-left: 0;
        }

        th:nth-child(7) {
            width: 11% !important;
            padding: 0;
            padding-top: 10px;

        }

        th:nth-child(8) {
            width: 0% !important;
            padding: 0;
        }
    }

    th:nth-child(8) {
        display: none;
    }
}

.seasonal-booking {
    .select-button_container {
        margin-left: 0px;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .row {
        .form-group {
            .excel_Upload_button {
                margin: 10px 0px 10px 0px;
                display: flex;
                height: 48px;
                font-size: 20px;

                @media (min-width: 992px) {
                    justify-content: center;
                }

                img {
                    padding: 0px 10px 0px 0;
                    width: 34px;
                    height: auto;

                    @media (min-width: 992px) {
                        width: auto;
                        padding: 0px 10px 0px 40px;
                    }
                }

            }
        }
    }
    .dropzone__container{
        width:100%;
    }
}