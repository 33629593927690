.gsa-useragent-select-main-container {
    width: 75%;
    box-shadow: 1px 1px 15px #00000029;
    border-radius: 5px;
    margin: 5% auto;
    padding: 30px;

    .gsa-select-heading {
        font-size: 18px;
    }

    .gsa-select-inputsection {
        width: 40%;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 768px) {
            width: 100%;
        }
        .gsa-select-inputsection-heading {
            font-size: 20px;
            font-weight: bold;
        }
        .error-show-area {
            display: block;
            height: 35px;
        }
        
        .gsa-select-inputsection-button {
            width: 100%;
            padding: 5px 30px;
            font-family: "EtihadAltis-Book", sans-serif;
        }
    }
}