@import '../../../assets/scss/abstracts/abstracts';

html,
body {
    background: $white;
}

.cargoportal-main-container{
    min-height: calc(100vh - 179px);
}

.container-lg {
    max-width: 1328px !important;
}

.container-lg {
    max-width: 1328px !important;
    @media (min-width: 1368px) {
       padding-left: 0;
       padding-right: 0;
    }
}


.cargo-portal-container {
    .page-heading {
        text-align: left;
        font-size: 32px;
        letter-spacing: 0px;
        padding-top: 34px;
        padding-bottom: 10px;
        font-family: $font-medium;
        padding-left: 5px;
        padding-right: 6px;
        line-height: 40px;
    }

    .page-sub-heading {
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
        padding-bottom: 17px;
        font-family: $font-medium;
    }

    .page-sub-head {
        font-size: 20px;
        text-align: left;
        letter-spacing: 0px;
        // padding-bottom: 17px;
        line-height: 30px;
        font-family: $font-medium;
        margin-bottom: 0;

        &.notification {
            padding-top: 1.7rem;
            padding-bottom: 0.4rem;

            @media (min-width: 992px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        @media (min-width: 992px) {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }

    .form-info {
        font-size: 18px;
        text-align: left;
        font-weight: normal;
        font-family: $font-book;
        line-height: 15px;
    }

    .form-item__label {
        font-family: $font-book;
        font-size: 14px;
        color: $textcolor !important;

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }

    .currency-item {
        font-size: 18px !important;
        padding-left: 1.3rem !important;
    }

    .form-item__field {
        font-family: $font-book;
        min-height: 50px;
        border-radius: 12px;

        @media (min-width: 992px) {
            font-size: 20px;
            min-height: 50px;
            border-radius: 12px;

        }

        &.p-calendar {
            &:focus-within {
                box-shadow: 0 0 5px #005f96;
                border: 1px solid #005f96;
            }

            .p-inputtext {
                border: none;
                padding: 0;
                width: 100%;

                &:focus {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    border-color: none;
                }
            }
        }
    }

    .form-item--disabled {
        .form-item__field {
            background: #DCDBDB 0% 0% no-repeat padding-box;
            border-color: #DCDBDB;
            color: #4e4c4a;
        }
    }

    .form-item__field[readonly] {
        padding: 0px .75rem;
        min-height: auto;
        margin-top: 0;
        background: transparent;
        border: none;
        padding-left: 0;
    }

    .form-item[class*=col-] {
        margin-bottom: 1.2rem;

        @media (min-width: 992px) {
            font-size: 20px;
            margin-bottom: 0;
            padding-left: 10px;
            padding-right: 11px;
        }
    }

    .form-item__error {
        font-size: 16px;
        font-family: $font-family;
    }


    .form-item--checkbox {
        .form-item__field:checked+.form-item__label:before {
            background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M1 7l3.876 3.287a1.024 1.024 0 0 0 1.425-.153L13 2" stroke="%23ffffff" stroke-width="1.3" fill="none" fill-rule="evenodd"/></svg>');
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            overflow: hidden;
            background-color: $etihadNewDarkBlue;
        }

        .form-item__field:checked:disabled+.form-item__label:before {

            background-color: #DCDBDB;
            background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M1 7l3.876 3.287a1.024 1.024 0 0 0 1.425-.153L13 2" stroke="%234e4c4a" stroke-width="1.3" fill="none" fill-rule="evenodd"/></svg>');
        }

        .form-item__field:disabled+.form-item__label:before {

            background-color: #DCDBDB;
        }
    }

    .form-item--radio {
        .form-item__field:checked~.form-item__label::after {
            background: $etihadNewDarkBlue
        }
        .form-item__label:hover:after{
            background-color: transparent;
        }
    }


    .table {
        color: $textcolor;

        th {
            border-bottom: 1px solid #ddd;
            font-weight: 400;

        }

        @media (min-width: 992px) {
            td {
                font-family: $font-book;
                font-size: 20px;
            }
        }

    }

    .portal-dialog {
        .p-dialog {
            font-family: $font-book !important;
            margin: 0 1rem;

            @media (min-width: 992px) {
                margin: 0;
            }

            .p-dialog-header {
                background-color: $white;
                border: none;
                padding: 1rem;

                @media (min-width: 992px) {
                    padding: 31px 31px 15px 31px;
                }

                .p-dialog-title {
                    font-size: 32px;
                    font-weight: normal;
                }

                .p-dialog-header-icon:focus {
                    outline: none !important;
                }

                .p-dialog-header-icon {
                    .p-dialog-header-close-icon:before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        background: url('../../images/icon-close-alert.png') no-repeat;
                        background-position: center;
                        display: inline-block;
                    }
                }
            }

            .p-dialog-content {
                padding: 0 16px 20px;
                font-weight: normal;
                text-align: center;
                color: #4e4c4a;

                @media (min-width: 992px) {
                    padding: 0 31px 20px;
                }

                h3 {
                    font-size: 24px;
                    font-family: $font-family;

                    @media (min-width: 992px) {
                        font-family: $font-book;
                        font-size: 32px;
                        font-weight: normal;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: normal;

                    @media (min-width: 992px) {
                        margin-top: -20px;
                        line-height: 42px;
                    }

                }

                span {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .p-dialog-footer {
                padding: 8px 31px 30px;
                border: none;
                text-align: center;
            }
        }

        .p-chips-multiple-container {
            flex-wrap: wrap;
            padding: 2px 7px 4px;
            border-radius: 12px;
        }

        .p-chips .p-chips-multiple-container {
            .p-chips-token {
                background: $primary;
                margin-top: 4px;
                margin-bottom: 2px;
                color: $white;

                .p-chips-token-label {
                    font-family: $font-book;
                    font-size: 16px;
                }

                .p-chips-token-icon {
                    color: $white;
                    font-size: 18px;
                }
            }

            .p-chips-input-token {
                input {
                    font-family: $font-book;
                    font-size: 16px;
                }
            }
        }

        .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
            box-shadow: 0 0 5px #005f96;
            border: 1px solid #005f96;
        }
    }

    .input-info {
        font-weight: normal;
    }

    .portal-accordion.p-accordion {
        .p-accordion-header {
            .p-accordion-header-link {
                background: $white;
                font-size: 20px;
                font-family: $font-medium;
                font-weight: normal;
                border-left: none;
                border-right: none;
                padding-top: 26px;
                padding-bottom: 26px;

                .p-accordion-toggle-icon {
                    position: absolute;
                    right: 10px;
                }
            }
        }

        .p-accordion-tab {
            .p-accordion-content {
                padding: 26px 0;
                border-left: none;
                border-right: none;
            }
        }

        .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
            background: $white;
            color: $textcolor;
            border-bottom: none;
        }

        .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
            box-shadow: none;
            outline: none !important;
        }
    }

    .section-heading {
        font-size: 24px;
        font-family: "EtihadAltis-Book", sans-serif;
        letter-spacing: 0px;
        line-height: 40px;
        margin: 0;
        margin-bottom: 14px;
        font-weight: 300;
        text-align: left;

        @media screen and (min-width:992px) {
            font-size: 32px;
            text-align: center;
            margin-bottom: 0;
        }
    }

    .section-sub-heading {
        font-size: 24px;
        font-family: $font-book;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 24px;
    }

    .paragraph-text {
        font-size: 20px;
        font-family: $font-book;
        letter-spacing: 0px;
        line-height: 28px;
    }

    .label-text {
        font-size: 16px;

        @media (min-width: 992px) {
            font-size: 20px;
        }
    }

    .primary-button {
        letter-spacing: 0.2px;
        padding: 0px 24px;
        gap: 4px;
        height: 48px;
        color: white;
        background: $buttonNew;
        border-radius: 25px;
        font-family: $font-bold;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        border: none;
        box-shadow:none !important;

        &:hover {
            background-color: $buttonNewHover;
            border: none
        }

        &:active {
            background-color: $buttonNewPressed;
            border: none
        }

        &:focus {
            background-color: $buttonNewPressed;
            border: none !important;
            outline: none !important;
        }
        &:disabled {
            color: #6B7280;
            background-color: $ethihadWhite;
            &:hover {
                background-color: $ethihadWhite!important;
              }
        }
    }

    .secondary-button {
        border: 1px solid #2E4755;
        border-radius: 25px;
        color: #2E4755;
        background-color: $white;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
        gap: 4px;
        height: 48px;
        font-family: $font-bold;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;

        &:hover {
            color: $white;
            background-color: #497188;
        }

        &:active {
            color: $white;
            background-color: #2E4755;
        }

        &:disabled {
            color: #9CA3AF;
            background-color: #E5E7EB;
        }

        &:focus {
            color: $white;
            background-color: #2E4755;
            outline: none !important;
        }
    }

    .custom-select-button .p-button {
        border-radius: 12px !important;
        border: 1px solid #ccc !important;
        background-color: white!important;
        color: $textcolor !important;
        font-weight: bold;
        width: 120px;
        height: 40px;
        transition: all 0.3s ease-in-out;
      }
      
      .custom-select-button .p-button.p-highlight {
        background-color: $etihadNewDarkBlue !important;
        color: white !important;
      }
      
      .custom-select-button .p-button:first-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      
      .custom-select-button .p-button:last-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      .custom-select-button .p-button:not(:first-child):not(:last-child) {
        border-radius: 0px !important;
    }

    .search-panel__scc-autocomplete,
    .p-inputwrapper-filled,
    .selected-item_sub_item {
        border-radius: 12px;
    }

    .link {
        color: $linkColor !important;
        font-family: $font-family !important;

        &:hover {
            color: $linkColorHovered !important;
        }

        &:active {
            color: $linkColorPressed !important;
        }
    }

    .l-checkbox input[type=checkbox]:checked+label {
        font-size: 20px;
        color: $black;
    }

    .l-checkbox input[type=checkbox]:checked+label:before {
        background-color: $etihadNewDarkBlue;
        border: none;
    }

    .l-radio label {
        line-height: 20px;
        font-size: 20px;
    }

    .l-radio label.checked::after {
        background-color: $etihadNewDarkBlue;
        border: none;
        top: 3.9px;
        left: 3.9px;
    }

    .p-splitbutton {
        .p-button {
            box-shadow: none !important;
        }
    }
}