@import '../../assets/scss/abstracts/abstracts';
.portal-popup-modal {
.p-dialog{
    margin: 0 1rem;
    font-family: "EtihadAltis-Book", sans-serif;
    width: 100%;
    @media (min-width: 992px) { 
      width: 42vw;
      margin: 0 auto;
    }
    // @include respond(md) {
    //   width: 60%;
    // }
    // @include respond(sm) {
    //   width: 80%;
    // }
    &-content{
      background-color: $white;
      .selected-file-panel {
        display: flex;
        align-items: centre;
        border-radius: 12px;
        height: auto;
        @media (min-width: 992px) { 
          height: 45px;
        }
        .button-choose{
          border-radius: 12px;
        }
        label.button-choose {
          white-space: nowrap;
      }
      }
    }
    &-header{
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: $white;
      color: #4D4F5C;
      font-size: 32px;
      .p-dialog-title{
        font-weight: 500;
        font-size: 1.2rem;
      }
    }
    &-footer{
      border: none !important;
      background-color: $white;
    }
      .button-first{
      font-size:20px;
      background-color: $primary;
      color: $white;
      border-color: $primary;
      box-shadow: none !important;
      outline: none;
      //width: 90px;
      font-family: $font-bold;
      @media (min-width: 992px) { 
        padding: 10px 30px;
      }
      &:enabled:hover{
       background-color: #976F0F;
      }
      &:hover{
        background-color: #976F0F;
        color: #fff;
      }
      }
      .button-second{
      font-size:20px;
      background-color: white;
      box-shadow: none !important;
      //color: $white;
      //border-color: $primary;
      color: #4E4C4A;
    border: solid 1px #4E4C4A;
      outline: none;
      //width: 90px;
      font-family: $font-bold;
      @media (min-width: 992px) { 
        padding: 10px 30px;
      }
      &:enabled:hover{
         background-color: #4E4C4A;
    color: #fff;
      }
      &:hover{
           // background-color: $white !important;
            //border: 1px solid $primary;
           // border-color: $primary !important;
            //color: $primary !important;
            background-color: #4E4C4A;
    color: #fff;
        }
      &:active{
        //background-color: $white;
        //border: 1px solid $primary;
        background-color: #4E4C4A;
    color: #fff;
      }
    }
    .p-dialog-header{
      padding: 1rem;
      border: none;
      display: flex;
      justify-content: center;
      @media (min-width: 992px) { 
        padding: 62px 32px 12px;
      }
      .p-dialog-title {
        font-size: 24px;
        font-family: $font-family;
        text-align: center;
        @media (min-width: 992px) { 
          font-size: 32px;
          font-family: $font-book;
        }
      }
    }
    .p-dialog-content {
      font-family:$font-book;
      font-size:16px;
      padding: 1rem;
      justify-content: center;
      display: flex;
      @media (min-width: 992px) { 
        padding: 10px 60px ;
      }
    }
    .p-dialog-footer {
      padding: 1rem;
      display: flex;
      justify-content: center;
      @media (min-width: 992px) { 
        padding: 30px 32px 35px;
      }
     
    }
    
    }
    .closeImagePopup{
      position: absolute;
      width: 12px;
      height: 12px;
      right: 25px;
      top: 25px;
      cursor: pointer;
  }
  #button-first-ok{
    .p-button-label {
      position:relative;
      right:3px;
    }
  }
  #button-first-cancel{
      .p-button-label {
          position:relative;
          right:2px;
          }
      }
  }