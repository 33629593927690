@import "../../assets/scss/abstracts/abstracts";

.form-item {
  &__field-draft {
    border: 1px solid #4E4C4A;
    width: 100%;
    height: 45px;
    border-radius: 12px;
    cursor: pointer;
    padding-left: 0.75rem;
    padding-right: 2.5rem;
    background-repeat: no-repeat;
    background-position: center right 0.75rem;
    font-family: $font-book !important;
    font-size: 20px;
    color: #4E4C4A;
    transition: box-shadow 0.35s;

    &:focus {
      box-shadow: 0 0 5px #005f96;
    }
  }

}

.name {
  height: 50px !important;
  cursor: auto !important;
  ;
}