@import '../../assets/scss/abstracts/abstracts';

.split-btn{
   height: 100%;
   .p-splitbutton{
      &-defaultbutton, &-menubutton {
         font-family: $font-bold !important;
         padding: rem(5) rem(15);
         background-color: $white;
         font-size: 18px;
         line-height: 1.5;
         border-radius: 4px;
         display: inline-block;
         letter-spacing: 0.2px;
         color: #2E4755;
         background-color: $white;
         border: 1px solid #2E4755;
         outline: none;
         &:hover{
            color: $white;
            background-color: #497188;
         }
         &:active{
            color: $white;
            background-color: #2E4755;
            outline: none !important;
         }
         &:focus{
            color: $white;
            background-color: #2E4755;
            outline: none !important;
         }
         &:disabled {
            color: #9CA3AF;
            background-color: #E5E7EB;
            outline: none !important;
        }

      }
      &-defaultbutton {
         border-bottom-right-radius: 0;
         border-top-right-radius: 0;
         border-top-left-radius:25px;
         border-bottom-left-radius:25px;   
         border-right:none;      
      }
      &-menubutton {
         border-bottom-left-radius: 0;
         border-top-left-radius: 0;
         border-top-right-radius:25px;
         border-bottom-right-radius:25px;
         position: relative;
         .pi-chevron-down {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
   .p-menu-overlay{
      width: 54px;
      border-radius: 12px;
      overflow: hidden;
      ul{
         margin-bottom: 0;
      }
      .p-menuitem-link:focus{
         box-shadow: none;
         outline: none !important;
      }
      * {
         text-decoration: none;
         &:hover{
            text-decoration: none;
         }
      }
   }
}
