@import '../../assets/scss/abstracts/abstracts';

.menu-main-wrapper{
    
    .modal{
        overflow-x: hidden;
        overflow-y: auto;
        height: auto;
        @media only screen and (max-width: 767px){
            padding: 16px!important;
        }
    }

    .shared-expcoll-panelgroup-container *{
        font-family: $font-family;
    }

    .eag-common-com-modalpopup .modal-content{
        background-color:#2E4755 !important;
        color: white !important;
        border-radius: 12px;
        @media (min-width: 768px){
            .modal-content {
                box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
                background-color:$etihadNewDarkBlue !important;
            }
        }
         .modal-body {
            padding: 0 0 15px!important;
        }
        .modal-header{
            padding: 15px !important;
        }
    }
    .shared-expcoll-panelgroup-container .shared-expcoll-panel-container{
        padding: 0;
        .panel-title .shared-expcoll-panel-title[aria-expanded=true] {
            background: #e5e4e3;
        }
    }
    .panel-group .panel+.panel {
        margin-top: 0;
    }
    .panel-title .shared-expcoll-panel-title {
        padding: 16px;
        display: block;
        .shared-expcoll-panel-title[aria-expanded=true] {
            background: #e5e4e3;
        }
    }
    .modal-title{
        text-transform: none;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        font-family: $font-family;
        color: white;
    }

    .submenu-wrapper{           
        .shared-expcoll-list-group-heading{
            margin: 0 !important;
            padding: 17px 0 17px 32px;
            border-top: 1px solid #e5e4e3;
            color: white !important;
        }
        .list-group-item{
            float: left;
            width: 100%;
            color: white !important;
            border-top: 1px solid #e5e4e3;
            .shared-expcoll-body-margin-left{
                display: flex;
                align-items: center;
                height: 49px!important;
                margin-left: 48px!important;
                a {
                    font-family: $font-book;
                    color: white !important;
                }
            } 
        }
    }

    .shared-expcoll-panelgroup-container .shared-expcoll-panel-container .shared-expcoll-panel-body-override{
            margin: 0 !important;
        }

    .shared-expcoll-panelgroup-container .shared-expcoll-panel-container .panel-body-extra-space{
            margin-left: 0!important;
        }

    .navigation-menu-header{
        font-size: 16px;
        line-height: 16px;
        color: $white !important;
        font-family: $font-family !important;
    }
    
    ::-webkit-scrollbar {
        width: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #4e4c4a;
        border-radius: 24px;
        border: 3px solid #fff;
    }
    ::-webkit-scrollbar-track {
        background: #fff;
    }
    .eag-common-com-modalpopup {
        .eag-common-modal-close-comp-cls {
            top: 4px;
        }
    }
}