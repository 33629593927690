@import '../../assets/scss/abstracts/abstracts';

.flight-enquiry{
  margin:51px 0 35px;
  .flight-details__right__sub{
    width:100%;
  }
  .advance-link-wrap{
    width: 100%;
    &:hover{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      color: $linkColorHovered;
    }
    &:active{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      color: $linkColorHovered;
    }
  .link{
    font-weight: 300;
    color: $linkColor;
    letter-spacing: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    word-break: break-word;
    font-family: $font-book;
    margin: 25px 0px 25px 0px;
    font-size: 20px;
    font-family: $font-book;
    &:hover{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      color: $linkColorHovered;
    }
    &:active{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      color: $linkColorHovered;
    }
  }
}
  .no-data{
    margin:20px 0px;
  }
    .seperator{
      border-right: 2px solid #000;
    height: 14px;
   
        font-size: 10px;
    }
    .flight-details__expandedDetails .flight-details__segment-info{
      border-right: 0px;
      width:100%;
    }
    .flight-details__expandedDetails{
    .flight-details__segments{
      width: 40%;
    }
    // .flight-details__layover{
    //   display: flex !important;
    // align-items: center;
    // justify-content: center;
    // width: 79%;
    // }

     .flight-details__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                margin-right: 6px;
                margin-top: 3px;
                width: 100%;
                flex-direction: column;
                align-items: center;
                @media (min-width: 992px) {
                  width: 80%;
                }    
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border-radius: 10px;
                  margin: 5px 0px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 6px 13px;
                  vertical-align: middle;
                  border: 0;
                  @media (min-width: 992px) {
                    border: 1px solid #EDEDEC;
                    background: $white;
                    padding: 3px 13px;
                  }    
                  
                }
                .segment-transit-line{
                  height: 20px;
                  width: 0;
                  border: none;
                  border-left: 1px solid #4E4C4A;
                  vertical-align: middle;
                  @media (min-width: 992px) {
                    height: 13px;
                    border-left: 1px solid $black;
                  }    
                }
              }


    }
  &__search{
  //box-shadow: 0px 0px 6px -2px #aaa;  
  padding: rem(0) 0px rem(30);
  background: $white;
  &__button{
    display: inline-block;
    padding-right: 0px;
    button{
      float: right;
      width: 100%;
      @media (min-width: 992px) {     
                float: right;
                width: auto;              
            }
    }
  
  }
 .p-datepicker {
    width: 440px;
    min-width: 280px;
    position: absolute;
     top: 32px !important;
}
  .p-autocomplete-panel {
    margin-top: 13px;
    position: absolute;
}
.inline-radios{
  .l-radio{
      display: inline-block;
      margin-right:10px;
  }
  label {
    font-size: 15px;
    color: #4d4f5c;
}
}
  &__title{
    // border-bottom: solid 1px $border;
    margin-bottom: 20px !important;
    color: $titleColor;
    display: inline-block;
    width: 100%;
    h3{
      display: inline-block;
      font-family: $font-medium;
      font-size: 1.75rem;
    }
  }
  &__title_scheduled{
    // border-bottom: solid 1px $border;
    margin-bottom: 5px !important;
    //margin-top: 30px !important;
    color: $titleColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (min-width: 992px) {     
                margin-top: 30px !important;              
            }
    h3{
      display: inline-block;
    }
    .icons-container{
      display: block;
      .icons-item{
        margin: 0px 10px 0px 10px;
        font-size:14px;
        padding-top: 20px;
        flex-direction: column;
        font-family:$font-book;
        @media (min-width: 992px) { 
                display: inline;    
                font-size:20px;
                align-items: center;
                flex-direction: row;       
            }
        img{
          height:24px;
          width:24px;
          @media (min-width: 992px) {     
                height:32px;
                width:34px;           
            }
        }
      }
    }
  }
  }

  .image-wrapper{
    width: 20px;
    padding-bottom: 4px;
    padding-right: 4px;
  }
  .portal-tab-menu .p-tabmenu .p-tabmenu-nav {
    .p-tabmenuitem.tab-flight-enquiry {
        .p-menuitem-link {
            border: none;
            background: $white;
            color: $tabLabel;
            border-bottom: 5px solid $etihadNewDarkBlue;
            font-family: $font-family
        }
    }
  }
}