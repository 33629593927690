@import '../../assets/scss/abstracts/abstracts';

.cargo-portal-container.portal-tabs.tabs-data-capture {
  
  padding: 22px 16px 30px;
  margin-top: 12px;

  .p-tabview .p-tabview-nav {
    display: flex;
    flex-wrap: nowrap;
    li .p-tabview-nav-link{
      width:100%;
    }
  }

  .page-heading{
    font-size:32px;
    font-weight: 500;
  }
  ul.error-list li {
    list-style-type: disc;
    margin-left: 35px;
  }
@media (min-width: 540px) { 
.capture-faqs-row {
  position: relative;
  .capture-faqs {
    position: absolute;
    right: 12px;
    top: 15px;
  }
}
}

@media (max-width: 540px) { 
  .capture-faqs-row {
    display: inline-block;
    width: 100%;
    .capture-faqs {
      right: 12px;
      top: 0px;
      float: right;
    }
  }
  }
 
}
// .form-item[class*=col-]{
//   margin-bottom: 1.6rem !important;
//   @media (min-width: 768px){  
//          margin-bottom: 1.2rem !important;
//     }
  
// }
.capture-awb {
    background-color: $white;
    padding: 40px 0 15px;
   .section-sub-heading{
      font-weight: 300;
   }
    
  .p-calendar {
    .p-inputtext {
      border: none;
      padding: 0;
      width: 100%;
    }
  }
  .autocomplete {
    width: 100%;
  }

  .form-control {
    height: 50px;
    font-family: $font-book !important;
    color: #4e4c4a;
  }
  .form-group .form-item__field {
    height: 50px;
    border-radius: 12px;
  }

  .p-autocomplete .p-inputtext{
    font-family: $font-book !important;
  }
  .p-autocomplete-panel{
    margin-top: 13px;
  }
  .form-group input[type=text], .form-group input[type=button] {
    font-size: 20px;
    color: #4e4c4a;
    width: 100%;   
  }
  .input-wrapper .p-autocomplete button {
    background: #fff;
    &.p-disabled {
      display: none;
    }
  }

  .routing-details {
    .route-item {
      border: 1px solid $textcolor;
    }
  }

  .rate-details {
    .rate-info {
      .rate-detail {
        padding-bottom: 24px;
        font-size: 20px;
        .rate-label {
          font-size: 20px;
          margin: auto;
          line-height: normal;
          font-family: $font-book;
          line-height: 28px;
        }
        .rate-value {
          font-size: 20px;
          font-family: $font-book;
          line-height: 28px;
        }
        &.total {
          .rate-label {
            margin: auto;
            font-family: $font-medium;
          }
          .rate-value {
            font-family: $font-medium;
            color: $primary
          }
        }
      }
    }
    .form-item--error {
      .p-autocomplete {
        box-shadow: 0 0 5px #cb2c30;
        border: 1px solid #cb2c30;
      }
    }
  }

  .search-panel__double_element .dropdown_div {
    width: 30%;
    
  }
  
    

  .p-datepicker {
    width: 440px;
  }

  .scc-row{
    .scc-wrapper{
      margin-bottom: 0 !important;
      margin-top: 0px;
      span{
        position: relative;
        margin-right: 7px;
        display: inline-block;
        .scccode{
          display: inline-block;
          white-space: nowrap;
          border-radius: 4px;
          background-color: $sccBgColor;
          border: 1px solid $darkGrey;
          border-radius: 12px;
          padding: 5px 6px;
          padding-right: 20px;
          margin-top: 0;
          cursor: pointer;
              font-size: 12px;
              font-family: $font-bold;
        }
      }
      
      .close-button{
        position: absolute;
        right: 2px;
        padding: 1px;
        border-radius: 50%;
        width: 16px;
        height: 15px;
        color: #777;
        cursor: pointer;
        top: 7px;
      }
    }
  }

  @media (min-width: 992px) {     
    .form-control {
      height: 50px;
      border: 1px solid $textcolor;
      &.search-panel__type.grey-div {
        border: none;
        box-shadow: none;
        padding-left: 0;
        padding-top: 14px;
        .l-checkbox {
          display: inline-block;
        }
      }
    }       
  }
  
  .borderRadius-R0, .form-group .form-item__field.borderRadius-R0 {
    border-bottom-right-radius:0px;
    border-top-right-radius:0px;
    border-right: none!important;
    border-top-left-radius:12px;
    border-bottom-left-radius:12px;
  }

  .borderRadius-L0, .form-group .form-item__field.borderRadius-L0 {
    border-bottom-left-radius:0px;
    border-top-left-radius:0px;
    border-left: none!important;
    border-top-left-radius:12px;
    border-bottom-left-radius:12px;
    .form-control {
      border-left: none;
    }
  }
  .p-dropdown-label.p-inputtext {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .cargo-details {
    border-radius: 0px;
    border: 2px solid rgb(104, 149, 245); 
    padding: 10px;
  }   
  .l-radio label {
        font-size:16px;
        display: inline-block;
        margin-left: 15px;
        padding-bottom: 5px;
        font-weight:300;
        line-height: 22px;
        font-family: $font-book;
        @media (min-width: 992px){ 
            font-size:20px;
        }
  } 
  
  .l-checkbox input[type=checkbox] + label {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight:300;
    font-family: $font-book;
    @media (min-width: 992px){ 
            font-size:20px;
        }
  }
  .red-border .form-item__field {
    border: solid 1px #FF0000;
  }
  button.link {
    font-size: 16px;
  }
  button.delete-button {
    margin-top: 40px;
    background: $white;
  }
  .other-customs-details {
    &.p-accordion-header {
      .p-accordion-header-link {
        border-bottom: none;
        border-color: #c8c8c8;
        border-radius: 0;
        padding-left: 0px;
        font-family: "EtihadAltis-Text", sans-serif;
        @media (min-width: 992px){  
          padding: 26px 14px;
          font-family: "EtihadAltis-Book", sans-serif;
    }

      }
    }
    &.p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: #c8c8c8;
        border-radius: 0;
      }
    }
  }
  .execution-details{
    &.p-accordion-header {
      .p-accordion-header-link {
        border-bottom: none;
        border-color: #c8c8c8;
        border-radius: 0;
        padding-left: 0px;
        font-family: "EtihadAltis-Book", sans-serif;
        @media (min-width: 992px){  
          padding: 26px 14px;
          font-family: "EtihadAltis-Medium", sans-serif;
    }

      }
    }
    &.p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: #c8c8c8;
        border-radius: 0;
      }
    }
  }
  .p-accordion-header {
      .p-accordion-header-link {
        border-color: #c8c8c8;
        border-radius: 0;
      }
    }
  .p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: #c8c8c8;
        border-radius: 0;
      }
  }
  .customs-details-form {
    &.p-toggleable-content {
      .p-accordion-content {
        border-bottom: none;
        border-color: #c8c8c8;
      }
    }
  }
  .chargehead-autocomplete-inner-wrapper{
    font-family: $font-book !important;

    .p-inputtext{
        border: none;
        padding: 0;
        width: 100%;
    }
    .p-inputtext:enabled:focus{
        box-shadow: none;
        border-color: #fff;
        -webkit-shadow:0;
    }
  }
  .close-icon {
    height: 40px;
    width: 40px;
    position: absolute;
    background: transparent;
    right: 20px;
    text-align: right;
    cursor: pointer;
    z-index: 99;
  }
  ::placeholder{
    color:#999;
  }  
  .error-info .p-inline-message {
    width: 100%;
    float: left;
    display: block;
    background: #F5D5D6;
    opacity: 1;
    font-family: $font-book;
    font-size:$subTitleFont;
    color:$textcolor;
    padding: 9px;
}
  .error-field {
    .p-autocomplete {
      border: 1px solid $red;
      box-shadow: 0 0 5px $red;
    }
  }
  .p-splitbutton.split-btn.p-disabled {
    color: #9CA3AF;
    button {
      color: #9CA3AF;
      background-color: $ethihadWhite;
    }
  }
  .captureAwb-Go{
    .primary-button{
    width:100%;
    margin-bottom: 15px;
    @media (min-width: 992px){  
      padding: 9px 30px;
      width: auto;
      min-width:88px;
      height:50px;
    }
    }
}
  .section-heading{
    font-size: 24px;
        font-family: "EtihadAltis-Text", sans-serif;
        letter-spacing: 0px;
        line-height: 40px;
        margin: 0;
        margin-bottom: 25px;
        font-weight: 300;
        text-align: left;
        @media screen and (min-width:992px) { 
            font-size: 32px;
            font-family: "EtihadAltis-Book", sans-serif;
        }
  }
  .section-sub-heading{
    font-size: 20px;
    margin-bottom: 5px;
    @media (min-width: 992px){  
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  .awb-action-wrapper{
    height: 50px;
    font-size: 20px;
    .p-l-10{
      padding-left: 5px;
      padding-right: 5px;
      @media (min-width: 992px){  
        padding-left: 10px;
      }
    }
  }
  .sup-info{
    min-height: 120px;
    @media (min-width: 992px){  
        min-height: 50px;
      }
  }
  .handling-info{
    min-height: 120px;
    @media (min-width: 992px){  
        min-height: 50px;
    }
  }
  .accounting-info{
    min-height: 120px;
    @media (min-width: 992px){  
        min-height: 50px;
    }
  }
  .notification-info-name{
    display: block;
    @media (min-width: 992px){  
        display: flex;
    }
  }
  .notify-name{
    width:345px;
    @media (min-width: 992px){  
        width:100%;
    }
  }
  .notify-telephoneNo{
    width:345px;
    @media (min-width: 992px){  
        width:100%;
    }
  }
  .notify-emailAdd{
    width:345px;
    @media (min-width: 992px){  
        width:100%;
    }
  }
  .capture-save{
    margin-left: 4px;
    margin-right: 4px;
    height: 50px;
    @media (min-width: 992px){  
         width:109.5x;
          height: 52.5px;
          margin-top: 0px;
          margin-left: 0px;
          //padding: 10 30 0 0;
    }
  }
  .captureHawb-secondary-button{
    width:100%;
    margin-left: 2px;
    margin-right: 2px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .print-button-awb{
    .split-btn{
      margin-left: 0 !important;
    }
    height: 50px;
  }
  .excel-upload-col {
    @media (min-width: 992px) { 
      margin-top: 6px;
    }
  }
  .portal-popup-modal .p-dialog .p-dialog-content {
      @media (min-width: 992px) {
        padding: 10px 51px;
      }
  }
}

.notifyList-second-row{
  width: 1500px;
}



