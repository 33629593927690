@import '../../../assets/scss/abstracts/abstracts';

.recent-booking-container{
overflow-x: auto;
box-shadow: 0px 1px 11px #00000029;
display: flex;
align-items: center;
width: 100%;
border-radius: 12px;
flex-wrap: wrap;
position:relative;
.awbnum-link, .track-text{
  font-size:16px;
}
@media (min-width: 992px) { 
  height:600px;
}
  &_wrap {
      overflow: auto;
      width: 100%;
      display: flex;
      height: 100%;
  }
table, thead, tbody, th, td, tr {
 width: 100%;
 height: 75px;
 padding-left: 20px;
 text-overflow: ellipsis;
 overflow: hidden;
 white-space: normal;
 table-layout: fixed;
 border-collapse: collapse;
}
table{
  @media (min-width: 992px) { 
    height: 99px;
    position:absolute;
    top:74px;
  }
}
tr {
   border-top: 1px solid #ccc;
   border-bottom: 1px solid #ccc;
   }
   th{
    color: rgb(78, 76, 74);
    font-family: EtihadAltis-Medium;
    font-size: 16px;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0px;
    line-height: 22.4px;
    width: 190px;
    @media (min-width: 992px) { 
      width: 90px;
    }
    &:first-child {
      @media (min-width: 1200px) { 
        width: 120px;
      }
    }
   }
td {
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  // padding: 0px 150px 0px 0px;
  margin: 0px 150px 0px 0px;
  padding-left: 20px;
  a.awb-text {
    font-size: 16px;
    @media (min-width: 1200px) { 
      font-size: 20px;
    }
  }
}
td:before {
  top: 12px;
  left: 6px;
  padding-right: 40px;
  white-space: nowrap;
  margin-left: -150px;
}
// @media
// only screen and (max-width: 768px) {
//   table, thead, tbody, th, td, tr {
//     display: block;
//     height: auto;
//     text-overflow: unset;
//     overflow: unset;
//     white-space: unset;
//   }
//   thead tr {
//     position: absolute;
//     top: -9999px;
//     left: -9999px;
//   }
//   tr { border: 1px solid #ccc; }
//   td {
//     border: none;
//     border-bottom: 1px solid #eee;
//     position: relative;
//     padding-left: 200px;
//     margin-left: 150px;
//   }
//   td:before {
//     position: absolute;
//     top: 12px;
//     left: 6px;
//     width: 200px;
//     padding-right: 40px;
//     white-space: nowrap;
//     margin-left: -150px;
//   }
//   td:nth-of-type(1):before { content: "AWB number"; }
//   td:nth-of-type(2):before { content: "Origin/Dest"; }
//   td:nth-of-type(3):before { content: "Details"; }
//   td:nth-of-type(4):before { content: "Status";}
//   td:nth-of-type(5):before { content: "";}
// }

.section-sub-heading {
  display: block;
  width: 100%;
  top:10px;
  @media (min-width: 992px) { 
    position:absolute;
   }
}
}

a.awb-text{
  color: rgb(0, 95, 150);
  font-family: EtihadAltis-Book;
  font-size: 20px;
  font-weight: 300;
  height: 28px;
  letter-spacing: 0px;
  line-height: 28px;
  text-decoration: underline;
  width: 154px;
  margin-bottom: 0px;
  cursor: pointer;
}

.track-text{
  color: rgb(0, 95, 150);
  font-family: EtihadAltis-Book;
  font-size: 20px;
  font-weight: 300;
  height: 28px;
  letter-spacing: 0px;
  line-height: 28px;
  width: 150px;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: $white;
  width: auto;
  }

.other-text{
  color: $textcolor;
  font-family: EtihadAltis-Book;
  font-size: 16px;
  font-weight: 300;
  height: 23px;
  letter-spacing: 0px;
  line-height: 22.4px;
  margin-bottom: 0px !important;
}
#booking-list{
    @media (min-width: 768px) { 
      position:absolute;
      top:80px;
    }
}