@import '../../../assets/scss/abstracts/abstracts';

.form-item__error:empty {
    display: none;
}
.custom-padding {
    padding-right: 15px;
    padding-left: 15px;
    @media (min-width: 992px) { 
        padding-right: 0;
        padding-left: 0;
     }
}

.cargo-portal-container {
    .dialogWrapper {  
        margin: 0 1rem;      
        @media (min-width: 992px) {
            width: 50vw;
            max-width: 670px;
        }
    }
    .booking-validation-error {
        margin-bottom: 0;     
        @media (min-width: 992px) {
            margin: 0 0 10px;
        }
    }
    .custom-mobile-mt-3 {
        margin-top: 1.9rem;
        @media (min-width: 992px) { 
            margin-top: 0;
         }
    }
    .custom-mobile-mb-4 {
        margin-bottom: 1.5rem;
        @media (min-width: 992px) { 
            margin-bottom: 0;
         }
    }
    .custom-mobile-row {
        margin-right: 0;
        margin-left: 0;
        @media (min-width: 992px) { 
            margin-right: -15px;
            margin-left: -15px;
         }
    }
    .custom-mb-1 {
        margin-bottom: 0;
        @media (min-width: 992px) { 
            margin-bottom: 1rem;
         }
    }
    .custom-mt-3 {
        margin-top: 0;
        @media (min-width: 992px) { 
            margin-top: 1rem;
         }
    }
    .custom-mt-4 {
        margin-top: 0;
        @media (min-width: 992px) { 
            margin-top: 1.5rem;
         }
    }
    .custom-mt-5 {
        margin-top: 0;
        @media (min-width: 992px) { 
            margin-top: 3rem;
         }
    }
    .custom-ml-5 {
        margin-left: 0;
        @media (min-width: 1200px) { 
            margin-left: 3rem;
        }
    }
    .custom-ml-4 {
        margin-left: 0;
        @media (min-width: 992px) { 
            margin-left: 1.5rem;
        }
    }
    .custom-ml-1 {
        margin-left: 0;
        @media (min-width: 992px) { 
            margin-left: 0.25rem;
        }
    }
    .custom-pl-0 {
        padding-left: 0;
        @media (min-width: 992px) { 
            padding-left: 15px;
        }
    }
    .custom-pl-2 {
        padding-left: 0;
        @media (min-width: 992px) { 
            padding-left: 0.5rem;
        }
    }
    .custom-pl-3 {
        padding-left: 0;
        @media (min-width: 992px) { 
            padding-left: 1rem;
        }
    }
    .custom-pl-4 {
        padding-left: 0;
        @media (min-width: 992px) { 
            padding-left: 1.5rem;
        }
    }
    .custom-pl-5 {
        padding-left: 0;
        @media (min-width: 992px) { 
            padding-left: 3rem;
        }
    }
    .custom-pr-5 {
        padding-right: 0;
        @media (min-width: 992px) { 
            padding-right: 3rem;
        }
    }
    .custom-pr-0 {
        padding-right: 0;
        @media (min-width: 992px) { 
            padding-right: 15px;
        }
    }
    .custom-mt-r-0 {
        margin-top: 1.5rem;
        @media (min-width: 992px) { 
            margin-top: 0;
        }
    }
    .custom-pr-r-0 {
        padding-right: 15px;
        @media (min-width: 992px) { 
            padding-right: 0;
        }
    }
    .custom-pl-r-0 {
        padding-left: 15px;
        @media (min-width: 992px) { 
            padding-left: 0;
        }
    }
    .form-item[class*=col-]{
        &.user_id {
            margin-bottom: 1.9rem;
        }
    }
    .switch {
        .switch-label {
            margin-bottom: 0;
            @media (min-width: 992px) {
                margin-bottom: 5px;
            }
        }
    }
    #userTable {
        .table {
            thead {
                tr {
                    padding-bottom: 0;
                    display: flex;
                    @media (min-width: 992px) { 
                        display:table-row;
                        padding: 1rem 0;
                    }
                    th {
                        padding-bottom: 0.4rem;
                        border: 0;
                        @media (min-width: 992px) { 
                            padding-bottom: 1.4rem;;
                            border-bottom: 1px solid #ddd;
                        }
                    }
                }
                clip-path: inset(0);
                position: relative;
                .column-head-width-0 {
                    flex: 1;
                    padding-left: 0;
                    @media (min-width: 992px) { 
                        padding-left: 1.4rem;
                    }
                }
                .column-head-width-1, .column-head-width-2 {
                    display: none;
                    @media (min-width: 992px) { 
                        display: revert;
                    }
                }
            }
            tbody {
                border-bottom: 1px solid #dcdbdb;
                tr {
                    &:first-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                    display: flex;
                    padding: 0;
                    @media (min-width: 992px) { 
                        display: table-row;
                    }
                    &:first-child {
                        @media (min-width: 992px) { 
                            padding: 1rem 0;
                        }
                    }
                    .switch {
                        margin: 0;
                    }
                }
                .table__body-td {
                    padding: 1.7rem 0;
                    border-top: 1px solid #dcdbdb;
                    &.column-body-width-name {
                        flex: 1;
                        align-items: center;
                        display: flex;
                        @media (min-width: 992px) { 
                            display: table-cell;
                        }
                    }
                    @media (min-width: 992px) { 
                        padding: 1.4rem;
                    }
                }
                .column-body-width-id, .column-body-width-time {
                    display: none;
                    @media (min-width: 992px) { 
                        display: revert;
                    }
                }
            }
        }
        #userList {
            margin-bottom: 8px;
            @media (min-width: 992px) { 
                margin-bottom: 20px;
            }
        }
    }
    .form-item {
        &.lang-selector {
            margin-bottom: 1.5rem;
            @media (min-width: 992px) { 
                margin-bottom: 0;
            }
        }
        &.custom-m-0 {
            margin-bottom: 0;
        }
    }
    .header-comp {
        height: 57px;
        @media (min-width: 992px) { 
            height: 75px;
        }
        .header-primary {
            height: 57px;
            @media (min-width: 992px) { 
                height: 72px;
            }
        }
    }
    .footer .navigation__copyright {
        padding: 16px 0 24px 0;
        @media (min-width: 992px) { 
            padding: 32px 0 16px;
        }
    }
    .secondary {
        &.button_cancel {
            margin-bottom: 24px;
            @media (min-width: 992px) { 
                margin-bottom: 0;
                margin-left: 1.5rem;
            }
        }
    }
    .user-privileges  {
        &.add-account {
            .previlege_label {
                padding-bottom: 1.4rem;
                @media (min-width: 992px) { 
                    padding-bottom: 1rem;
                }
                label {
                    margin-top: 0;
                    line-height: normal;
                    font-size: 16px;
                    font-family: "EtihadAltis-Book", sans-serif;
                    @media (min-width: 992px) { 
                        font-size: 20px;
                    }
                }
            }
        }
        margin-bottom: 1.3rem;
        padding-left: 5px;
        @media (min-width: 992px) { 
            margin-bottom: 0;
            padding-left: 1rem;
        }
        .form-item__label {
            font-size: 20px;
            margin-top: 2rem;
            font-family: "EtihadAltis-Text", sans-serif;
            @media (min-width: 992px) { 
                font-size: 14px;
                margin-top: 0;
                font-family: "EtihadAltis-Book", sans-serif;
            }
        }
    }
    .links__list {
        padding: 25px 0 20px 0;
        @media (min-width: 992px) {
            padding: 26px 0;
        }
    }
    #weight-InfoTable{
        .table {
            width:auto;
            margin-bottom: 0;
            @media (min-width: 992px) { 
                width:100%;
                margin-bottom: 20px;
            }
            tr {
                display:table-row;
                }
                th,td{
                    display:table-cell;
                    font-size: 16px;
                    vertical-align: middle;
                    padding: 15px;
                    font-family: $font-book;
                    @media (min-width: 992px) {
                        padding: 8px;
                    }
                }
                td{
                    @media (min-width: 992px) { 
                        font-size: 20px;                        
                        vertical-align: top;
                    }
                }
            
            thead {
                clip: auto;
                clip-path: none;
                position: relative;
            }
        }
    }
    .allotment-table {
        .slick-slide {
            text-align: center;
            height: auto;
        }
        .table__body-th {
            font-size: 14px;
            font-family: 'EtihadAltis-Text';
            padding: 0;
            border-bottom: 0;
            @media (min-width: 992px) {
                font-family: "EtihadAltis-Medium",sans-serif;
                font-size: 16px;
                font-weight: 500;
                padding: 1.2rem 1.2rem 1.2rem 0;
                border-bottom: 1px solid #dcdbdb;
            }
        }
        .slick-prev, .slick-next {
            top: 0;
            transform: translateY(0);
            &.slick-disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
.search-panel {
    .dialogWrapper {  
        margin: 0 1rem;      
        @media (min-width: 992px) {
            width: 50vw;
            max-width: 670px;
        }
    }
    .item-group-additional.row {
        margin-bottom: 0;
        @media (min-width: 992px) { 
            margin-bottom: 8px;
        }
    }
    // .form-group.col-12 {
    //     margin-bottom: 0;
    //     @media (min-width: 992px) { 
    //         margin-bottom: 8px;
    //     }
    // }
    .additional-form__question {
        padding-right: 0;
        margin-bottom: 1rem;
        @media (min-width: 992px) { 
            padding-right: 15px;
        }
    }        
    // .shipping-item-row {
    //     .form-group.additional_scc {
    //         margin-bottom: 0;
    //     }
    // }
    .container_type {
        // &.form-group.col-12 {
        //     margin: 1rem 0;
        //     @media (min-width: 992px) { 
        //         margin: 0;
        //         margin-bottom: 1rem;
        //     }
        // }
        .p-selectbutton.p-buttonset {
            display: flex;
            .p-button {
                width: 50%;
                height: auto;
                @media (min-width: 992px) { 
                    width: auto;
                    height: 50px;
                }
            }
        }
    }
    .p-calendar {
        .p-datepicker {
            padding: 0px 28px 0px 21px;
            margin-top: 17px !important;
            @media (min-width: 992px) { 
                padding: 0px 48px 0px 41px;
             }
        }
    }
    .mobile-mt-2 {
        margin-top: 1.5rem;
        @media (min-width: 992px) { 
            margin-top: 0;
        }
    }
    .custom-pl-4 {
        @media (min-width: 992px) { 
            padding-left: 1.5rem;
        }
    }
    .custom-ml-3 {
        margin-left: 0;
        @media (min-width: 992px) { 
            margin-left: 1rem;
        }
    }
    .custom-mr-3 {
        margin-right: 0;
        @media (min-width: 992px) { 
            margin-right: 1rem;
        }
    }
    #pr_id_14 {
        width: 100%;
        // @media (min-width: 992px) {
        //     width: 100%;
        // }
    }
    .calendar-group {
        padding-top: 0.9rem;
        @media (min-width: 992px) {
            padding-top: 0;
        }
        .date-toggle-adjust {
            margin-bottom: 10px;
            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }
    }
    .custom-xs {
        flex: 1;
        display: flex;
        align-content: center;
        width: 100%;
        @media (min-width: 992px) {
            width: auto;
        }
        .p-radiobutton {
            margin-right: 0.3rem;
            margin-top: 0.2rem;
            @media (min-width: 992px) {
                margin-right: 0;
                margin-top: 0;
            }
        }
    }
}
.form-row{
    .notify_list {
        > div {
            margin-bottom: 24px;
            padding: 0;
            @media (min-width: 992px) { 
                padding-right: 5px;
                padding-left: 5px;
            }
            &:last-child {
                margin-bottom: 30px;
                @media (min-width: 992px) { 
                    margin-bottom: 24px;
                }
            }
        }
    }
}
.cta_wrap, .form-row > .cta_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    &.modifyBooking {
        flex-direction: column-reverse;
        @media (min-width: 992px) { 
            flex-direction: column;
        }
        .modifyBookingBack {
            margin-top: 1.5rem;
            @media (min-width: 992px) { 
                margin-top: 0;
            }
        }
    }
    @media (min-width: 992px) { 
        width: auto;
        display: block;
    }
}
.footerwithnavigation {
    padding: 0 0.5rem;
    @media (min-width: 992px) { 
        padding: 0 15px;
    }
}
/*Tabs STARTS*/
.p-tabview-title {
    font-weight: 500;
   /* @media (min-width: 992px) { 
        font-weight: 700;
    }*/
}
/*Tabs ENDS*/
.cargo-portal-container {
    &.flight_choose-err {
        padding: 2rem 0 0 0;
        @media (min-width: 992px) { 
            padding: 0 3rem;
        }
    }
}
.autocomplete_item_main {
    padding-right: 10px;
    @media (min-width: 992px) { 
        padding-right: 0;
    }
}
.warning-error:empty {
    display: none;
}
.temp_range {
    .select-button {
        display: flex;
        .p-button {
            padding: 10.5px 5px;
            @media (min-width: 992px) { 
                padding: 10.5px 13.5px;
            }
        }
    }
} 
.custm_pt{
    padding-top: 0.5rem;
    @media (min-width: 992px){  
        padding-top: 0;
    }
  }
  .custm_mb_view_booking{
    margin-bottom: 20px;
    @media (min-width: 992px){  
        margin-bottom: 1rem;
    }
  } 
  .cost_per_rate_mobile{ 
    font-size: 14px !important;
  }
  .spot_rate_status_mobile{
    font-size: 16px !important;
  }
.chart-container {
    width: 160px;
    margin-bottom: 1.5rem;
    @media (min-width: 992px) { 
        width: auto;
        margin-bottom: 0;
    }
}
.freighterSpan{    
    background: #9CB9C9;
    border-radius: 50%;
    padding: 3px;
    img{
    width: 17px !important;
    height: 17px !important;
     @media (min-width: 992px) { 
        width: 22px !important;
        height: 17px !important;
        margin-bottom: 1px;
    }

    }

}
.freighter-icon-list-span{
    background: #9CB9C9;
    border-radius: 50%;
    padding: 3px;
    img{
        width: 23px !important ;
        height: 16px  !important;
     @media (min-width: 992px) { 
        width: 23px;
        height: 20px; 
    }
}
}
.segmentInfo-Separator{
    border-top: 1px solid #dcdbdb;
}
#pr_id_2 {
    .p-carousel-item {
        &.p-carousel-item-active {
            flex: 1 0 95%;
            margin-right: 0;
            &:last-child {
                flex: 1 0 100%;
                padding: 0;
                @media (min-width: 992px) { 
                    flex: 1 0 33.333333333333336%;     
                    padding-right: 15px;  
                }
            }
            @media (min-width: 992px) { 
                flex: 1 0 100%;
                margin-right: 10px;                
            }
            @media (min-width: 992px) { 
                flex: 1 0 33.333333333333336%;       
            }
        }
    }
}
.p-items-hidden .p-carousel-item {
    visibility: visible;
    @media (min-width: 992px) { 
        visibility: visible;
    }
}
.quote-details {
    .flight-details__expanded {
        .flight-details__elapse {
            margin-top: -47px;
            @media (min-width: 992px) { 
                margin-top: -35px;
            }
        }
    }
}