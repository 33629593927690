@import '../../assets/scss/abstracts/abstracts';

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}


.p-dialog-content .infopet {
  line-height: 20px !important;
}

.booking-confirmation-page {
  min-height: calc(100vh - 71px);
  padding-bottom: rem(30);
  margin-top: 51px;

  .flight-details__right__sub__pricing {
    display: none;

    @media (min-width: 992px) {
      display: flex;
    }
  }

  .confirm_title {
    color: #4D4F5C;
    font-size: 22px;
    font-family: "EtihadAltis-Text";
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  }

  .quote-details {
    background: $white;
    padding: 0;
    border-radius: 4px;

    .title {
      font-size: 2rem;
      //margin-bottom: -10px;
      color: $titleColor;
      font-family: $font-medium;

      .status-confirmed {
        color: #7A9A01
      }

      .status-queued {
        color: #F68D2E
      }

      &.mobile {
        border-radius: 12px;
        text-align: center;
        white-space: nowrap;
        align-items: center;
        font-size: 20px;
        font-family: "EtihadAltis-Book";
        color: #4e4c4a;
        padding: 24px;
        margin-bottom: 28px;

        .confirmation_status {
          padding-right: 1rem;
        }

        &.status-confirmed {
          background: #e4ebcc;
        }

        &.status-queued {
          background: #ffecb5;
        }

        .indicator {
          color: #f68d2e;
          width: 17px;
          height: 30px;
          margin-right: 24px;
        }
      }
    }

    .sub-title {
      font-family: $font-bold;
      margin-top: 10px;
      color: $titleColor;
      font-size: $subTitleFont;
    }

    .flight-summary {
      border: 1px solid $border;
      margin: 25px 0;

      .shipment-summary {
        margin: 0;
        color: $textcolor;
        font-size: 18px;
        border: none;

        &__list {
          display: block;
          width: 100%;
          text-align: left;
          padding-left: 1rem;

          @media (min-width: 992px) {
            display: flex;
            width: 100%;
            align-items: center;
            padding-left: 0;
          }

          .d-flex {
            margin-left: auto;
          }
        }

        &__item {
          border: none;
          font-size: 16px;
          padding: 0;
          // @media (min-width: 992px) {
          //   padding: 0px 15px;
          //   font-size: 14px;
          // }
        }

        &__button {
          position: relative;

          @media (min-width: 992px) {
            padding: 0 7px;
          }

          button {
            width: 100%;
            margin-top: 1rem;

            @media (min-width: 992px) {
              width: auto;
              padding-left: 29px;
              margin-top: 0;
            }

            &:hover {
              color: #4E4C4A;
              background: white;
            }
          }
        }

        &__icons {
          left: 13px;
          position: absolute;
          top: 10px;
        }
      }
    }






    .cargo-details {
      padding: 20px 30px 30px 30px;
      margin: 5px 0px 20px;
      margin-top: 15px;
      display: flex;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 6px;

      @include respond(md) {
        display: block;
      }

      &__col {
        display: flex;
        flex-direction: column;
        width: 47%;

        @include respond(md) {
          width: 100%;
        }
      }

      &__item {
        @media (min-width: 992px) {
          padding-top: 10px;
          height: 30px;
          margin: 10px 5px 10px 0;
          display: flex;
        }

        @media (min-width: 992px) {
          height: 20px;
        }

        label {
          font-family: $font-medium;

          @media (min-width: 992px) {
            width: 40%;
            font-family: $font-family
          }
        }

        // .value-field{
        //   width: 58%;
        // }
      }

      .value-field {
        font-size: $rateFont;
        color: $textcolor;
        font-family: $font-book;
        display: inline-block;
        padding-left: 10px;

        @media (min-width: 992px) {
          padding-left: 0;
        }

        &__sub {
          line-height: 1.7;
          font-size: 20px;
          font-family: $font-family;
          color: #959492;
        }
      }

      label {
        font-size: $labelFont;
        color: $textcolor;
        font-family: $font-book;
      }
    }


    .awb-details {
      margin: 20px 0px;
      padding-left: 0;

      .details {
        padding-bottom: 20px;
      }

      .awb-title {
        margin-bottom: 10px;
        color: $titleColor;
        font-size: $subTitleFont;
      }
    }


    .awb-details .red-border .form-control {
      border: none;
      border-bottom: solid 1px red;
    }

    .flight-details__connection .view-label {
      left: 93px;
      bottom: -10px;
    }

    .m-r-10 {
      margin-right: 10px;
    }

    .m-r-0 {
      margin-right: 0px !important;
    }

    .p-l-0 {
      padding-left: 0;
    }

    .p-r-0 {
      padding-right: 0;
    }

    .p-t-0 {
      padding-top: 0 !important;
    }

    .p-r-5 {
      padding-right: 5px;
    }

    .p-r-7 {
      padding-right: 5px;
    }
  }

  @media print {

    padding-bottom: 0 !important;
    margin-top: 0 !important;


    a:after {
      content: "(" attr(href)")";
    }

    .no-print {
      display: none !important;
      visibility: hidden !important;
    }

    .logo {
      width: 55px !important;
    }

    .density-print {
      position: relative;
      top: 20px;
      padding-bottom: 150px;
      //padding: 95px 25px 290px 55px;

    }

    .p-r-7 {
      padding-left: 68px;
    }

    .floater-logo {
      display: block;
      position: fixed;
      bottom: 45px;
      right: 10px;
      padding-right: 25px;
    }

    .footer-text {
      display: block;
      position: fixed;
      bottom: 5px;
      right: 10px;
      padding-right: 25px;
    }

    .header {
      display: block;
    }

    .page-number-1 {
      display: flex;
      position: absolute;
      bottom: 20px;
      left: 45px;
    }

    .page-number-2 {
      display: flex;
      padding-top: 617px;
      page-break-after: always;

    }

    .charge-heading {
      page-break-before: always;
      font-family: $font-bold;
      margin-top: 10px;
      color: $titleColor;
      font-size: $subTitleFont;
      display: block;
      padding-top: 140px;
    }

    .logo-company {
      display: block;
      margin-bottom: 25px;

      .logo-company-image {
        position: fixed;
      }

      .company-name {
        font-size: 25px;
        margin-top: 15px;
        padding-top: 22px;
        text-align: right;
      }


    }

    .booking-details-print {
      padding: 5px;
      margin: 15px 20px 0px 0px;
      padding-left: 16px;
      padding-top: 0px;
      display: block;

      .plus-sign {
        border: solid 1px $border;
        border-radius: 5px;
        cursor: pointer;
        padding: 0px 8px;
        margin-left: 10px;
        background: #e6e3e3;
        font-size: 13px;
      }

      .row:first-child {
        border-top: 1px solid #d9d9d9;
      }

      .total {
        .total-cost {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        border-top:1px solid #d9d9d9;

        h4 {
          font-size: $subTitleFont;
          color: $titleColor;
          font-family: $font-bold;
          padding-bottom: 10px;
        }

        span {
          font-family: $font-bold;
          font-size: $grandTotalFont;
          color: $primary;
        }

        .exclude-text {
          font-size: $linkFont;
          color: $titleColor;
          width: 60%;
        }
      }

      .subcharges {
        border-bottom: none !important;

      }

      .charges-row {
        // margin-right: 16px;
        display: inline-block;
        padding-left: 0;
      }

      .sub-charges {
        padding: 0;
        border: 0 !important;

        .rate-field {
          font-size: 16px;
          font-family: $font-family;
        }

        .per-rate-field {
          font-size: 14px;
        }

        .charges-row {

          h4 {
            color: $titleColor;
            font-size: 16px;
          }

        }
      }

      h4 {
        color: $titleColor;
        font-size: $subTitleFont;
      }

      .rate-field {
        font-size: $subTitleFont;
        color: $titleColor;
        display: inline-block;
        font-family: $font-medium;
        text-align: right;
      }

      .per-rate-field {
        display: inline-block;
        font-size: $subTitleFont;
        color: $titleColor;
        text-align: right;
        width: 25%;
      }

      .charge-weight {
        font-size: $subTitleFont;
        color: $titleColor;
        font-family: $font-bold;

        .value-field {
          font-size: $rateFont;
          color: $textcolor;
          font-family: $font-bold;
          display: inline-block;

          &__sub {
            line-height: 1;
            font-size: 16px;
            font-family: $font-family;
            color: #959492;
          }
        }
      }

      .row {
        padding: 12px 0px 8px 0px;
        border-bottom: 1px solid $border;

      }
    }

  }
  .density-col {
    @media (min-width: 992px) {
      display: flex;
      max-width: 60%;
      vertical-align: middle;
    }
  }
}


.booking-details-print {
  display: none;
}

.cb-print-booking {
  font-size: 28px;

  label {

    color: $black !important;
  }
}

// .logos{
//       display:none;
//     }
.logo-company {
  display: none;
  justify-content: space-between;
}

.floater-logo {
  display: none;
}

.footer-text {
  display: none;
}

.charge-heading {
  display: none;
}

.page-number-1 {
  display: none
}

.page-number-2 {
  display: none
}

.dialog-container {
  .form-row.alert {
    margin: 0;
  }
}

.col-4 {
  font-size: 20px;
  font-family: $font-book;
}

.another_booking_mobile {
  background: rgb(237, 237, 236);
  margin-top: 48px;
  padding: 4px 9px;
  border-radius: 12px;
  width: 100%;
  font-family: $font-book;
  align-items: center;
  color: rgb(78, 76, 74);

  span {
    font-size: 16px;
    font-family: $font-book;
    flex: 1;
    text-align: left;
  }
}

.booking-confirmation-page {
  .shipment-summary {
    margin: 32px 0px 25px 0px;
    font-family: "EtihadAltis-Medium", sans-serif;
    border-radius: 2px;
    background: transparent;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid #C3C3C3;
    box-shadow: 0px 3px 6px #2d1f5008;
    padding: 11px 2px 15px 2px;

    @media (min-width: 992px) {
      padding: 11px 2px;
    }
  }

  .flight-details__right {
    flex-direction: row;
    margin-top: 5px;
    width: 100%;
    justify-content: flex-end;

    @media (min-width: 992px) {
      width: 50%;
    }

    .flight-details__right__arrow {
      padding-top: 6px;

      @media (min-width: 992px) {
        padding-top: 0;
      }
    }
  }
}