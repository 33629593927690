@import '../../assets/scss/abstracts/abstracts';


.capture-hawb-wrapper{
    
  background-color: $white;
  padding: 40px 0 15px;

  .section-heading{
    font-size: 24px;
        font-family: "EtihadAltis-Text", sans-serif;
        letter-spacing: 0px;
        line-height: 40px;
        margin: 0;
        margin-bottom: 25px;
        font-weight: 300;
        text-align: left;
        @media screen and (min-width:992px) { 
            font-size: 32px;
            font-family: "EtihadAltis-Book", sans-serif;
        }
  }

  .form-item label{
    font-size: 16px;
  }
  .p-calendar .p-inputtext{
    border: none;
    padding: 0;
    width: 100%;
  }
  .p-inputtext:enabled:focus{
    box-shadow: none !important;
    border-color: #fff !important;
   }
  
  .p-button:enabled:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .p-datepicker {
    width: 440px;
    min-width: 280px;
    position: absolute;
    top: 32px !important;
    }

 .p-datepicker table td > span.p-highlight {
    background: $dateSelected;
  }
  .p-autocomplete-panel {
    margin-top: 15px;
    position: absolute;
  }
  .hawb-autocomplete-inner-wrapper{
    .p-inputtext{
      border: none;
      padding: 0;
      width: calc(100% - 18px);
      font-family: $font-book !important;
    }   
    .p-autocomplete-panel {
      margin-top: 23px;     
    }
    
    .p-button{
      background: #fff!important;
      border-color: #fff;
      width: auto;
      border: none;
      line-height: 22px;
    }
    .pi-chevron-down{
      color: #4e4c4a;
    } 
  }
  .hawb-action-wrapper{
    min-height: 50px;
    font-size: 20px;
    .p-l-10{
      padding-left: 5px;
      padding-right: 5px;
      @media (min-width:992px){  
        padding-left: 10px;
      }
    }
  }
  .page-sub-title{
    color: inherit;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 400;  
    letter-spacing: 0px;
    line-height: 26px;
    @media (min-width: 992px){  
      font-family: $font-book;
      font-size: 32px;
      font-weight: 300;  
      letter-spacing: 0px;
      line-height: 40px;
      }
    }
  .section-border{
    border-bottom: 1px solid #dcdbdb;    
    padding-top: 1.8rem;
    @media (min-width: 576px){  
      margin: 0;
    }
  }

    
  .go-button{
    .primary-button{
      width:100%;
      margin-bottom: 15px;
      @media (min-width: 992px){  
        padding: 9px 30px;
        width: auto;
        min-width:88px;
        height:50px;
      }
      }
  }
  .m-t-25{
    margin-top: 25px;
  }
  .house-total-display{
    color: rgb(80, 63, 47);
    font-family:$font-book;
    font-size: 20px;
    font-weight: 300;       
    letter-spacing: 0px;
    line-height: 30px;
    @media (min-width: 992px){
      font-size: 24px;
    }
  }
  .icon-wrapper{
    width: 20px;
    padding-bottom: 4px;
    padding-right: 4px;
    color: #4c4c4c;
  }
  .link{
    font-size: 16px;
  }
  .red-border .form-item__field {
    box-shadow: 0 0 5px #cb2c30;
    border: 1px solid #cb2c30;
  }     
  ::placeholder{
    color:#999;
  }

  button.delete-button {
    margin-top: 40px;
    background: $white;
  }

  .other-customs-details {
    &.p-accordion-header {
      .p-accordion-header-link {
        border-bottom: none;
        border-color: #c8c8c8;
        border-radius: 0;
        padding-left: 0px;
        font-family: "EtihadAltis-Text", sans-serif;
        @media (min-width: 992px){  
          padding: 26px 14px;
          font-family: "EtihadAltis-Book", sans-serif;
    }

      }
    }
    &.p-accordion-header:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: #c8c8c8;
        border-radius: 0;
      }
    }
  }
  .hawb-bulkupload-link{
    width: 30%;
    font-size: 20px;
    font-family: "EtihadAltis-Book", sans-serif;
    color: #005f96;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }  

  .download-bulkupload{
    display: flex;
    flex-direction: row;
    justify-content: flex-end; 
    gap: 2rem;
    margin-left: auto;

    .hawb-exceldownload{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 47%;
      justify-content: space-between;
      .downld_temp{
        color: rgb(0, 95, 150);
        white-space: nowrap;
      }
    img{
      width:30px;
      margin-right: 1rem;
    }
  }
  }
  @media (max-width: 768px) {
    .download-bulkupload {
        margin-top: 15px;
    }
  }
}