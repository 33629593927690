@import '../../../../assets/scss/abstracts/abstracts';

.body {
    box-sizing: border-box;
}

.custom_overlay_panel {
    margin-top: 0;
    margin-left: 0;
    width: 80% !important;
    // height: 520px;
     height: auto;
    transform-origin: left bottom !important;
}

div {
    //border: solid 1px;
    box-sizing: border-box;
}

.form-container {
    align-items: flex-start;
}

.form-columns {
    //color: red !important;
    width: 50%;
    margin: 0;
    //border-left: solid 2px rgb(177, 176, 176);
    // height: 100px;
}

.border-BR {
    border-right: dotted 1px grey;
}

.popup-header {
    text-align: right;
    padding: 15px 0;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
}

.popup-footer {
    text-align: right;
    padding: 15px 0;
    border-top: 1px solid #d9d9d9;
    margin-top: 15px;
}
.checkbox-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.checkbox-custom {
    padding: 0 1rem 0 0;
}

.checkbox-custom .checkbox-item {
    position: relative;
    padding-top: 0;
    padding-left: 1.7857142857rem;
    display: flex;
}

.checkbox-custom .checkbox-item__label {
    font-size: 16px;
    line-height: 1;
    margin-left: 4px;
    padding-top: 2px;
    cursor: pointer;
    &.filter-title {
        font-family: $font-book;
    }
}

.checkbox-custom .checkbox-item__label:before {
    width: 20px;
    height: 20px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0.7142857143rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid $textcolor;
    border-radius: 2px;
    background-color: #fff;
}

.checkbox-custom .checkbox-item__field {
    position: absolute;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    clip: rect(0 0 0 0);
}

.checkbox-custom .checkbox-item__field:checked + .checkbox-item__label:before {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M1 7l3.876 3.287a1.024 1.024 0 0 0 1.425-.153L13 2" stroke="%23ffffff" stroke-width="1.3" fill="none" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    overflow: hidden;
    background-color: $etihadNewDarkBlue !important;
}

.custom-filterDate {
    .p-datepicker {
        min-width: 400px;
    }

    .p-datepicker table td.p-datepicker-today > span{
        background: #E8EFF2 !important;
    }
    .p-inputtext.p-component {
        font-size: 18px;
        color: #4D4F5C;
        font-family: "EtihadAltis-Book", sans-serif;
    }
}

.clear-flight-filter {
    text-align: right;
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    font-size: 15px;
    color: blue;
    cursor: pointer;
}


.input-drop-combo {
    width: 100%;
    margin-left: 15px;
    height: 45px;
}

.input-dropdown-wrapper{
    //border: solid 1px;
    width: 300px;
    height: 60px;
    margin-left: 14px;
    margin-top: 30px;
}

.validation-error{
    color: red;
    margin-left: 16px;
    padding-top: 6px;
}

.status-field{
    margin-top: 20px;
}

.disable-time-selection{
    pointer-events: none;
    opacity: 0.5;
}
.filter-link {
    font: 500 16px/24px $font-book;
    color: $textcolor;
    margin-left: 2px;
    padding: 5px 0;
    .icon-filter {
        padding-right: 12px;
    }
}

.flight-filter {
    .col-flt-no {
        padding-right: 20px;
    }
    .col-awb-prefix {
        padding-left: 10px;
    }
    .col-awb-no {
        padding-right: 16px;
    }
    .nudge {
        background-color: #F1EEED;
        padding: 10px;
        margin-top: 5px;
        border-radius: 10px;
        p {
            margin: 0;
        }
    }
}
.cargo-portal-container  {
    .form-item__field{
        &.std-input {
        position: absolute!important;
        width: 60%;
        top: 2px;
        border: none;
        min-height: 40px;
        height: 46px;
        left: 20px;
        z-index: 100;
        &:focus {
            box-shadow: none;
        }
        &.form-item--disabled {
            background: transparent;
            color: #4e4c4a;
            opacity: 0.7;
        }
        }
    }
    .p-multiselect  {
        height: 50px;
        width: 100%;
        font-family: $font-book;
        font-size: 18px;
        border-radius: 12px;
        .p-multiselect-label {
            height: 100%;
            padding: 9px 0.75rem;
        }

        .p-multiselect-items {
            padding: 5px;
        }
        .p-multiselect-header {
            padding: 0.429rem 16px ;

            .p-checkbox .p-checkbox-box.p-highlight {
                background-color: $etihadNewDarkBlue;
            }
        }

        .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
            background-color: #EDEDEC;
            color: #4e4c4a;
            margin-bottom: 5px;
            .p-checkbox-box {
                background-color: $etihadNewDarkBlue;
            }
            border-radius: 5px;
        }
    }

    .p-multiselect-filter-container .p-inputtext{
        border-radius: 12px;
    }
}
