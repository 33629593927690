@import '../../assets/scss/abstracts/abstracts';

.header {
  display: flex;
  padding: rem(5) rem(30);
  justify-content: space-between;
  align-items: center;
  box-shadow: -1px 0px 6px -2px #aaa;
  background: $white;


  .d-flex {
    display: flex;
  }

  &__right {
    display: flex;
    align-items: center;
    flex-direction: column;

    .username {
      margin-left: 10px;

      button {
        border-radius: 0;
        font-size: 13px;
        cursor: default;
        font-family: $font-book;
        opacity: 1;

        &:hover {
          background: $primary
        }
      }
    }
  }

  &__user {
    color: $textdark;
    font-size: rem(22);
  }

  &__signout {
    color: $textdark;
    font-size: rem(20);
    padding: 10px
  }

  &__banner {
    display: block
  }
}

.profile {
  background: $primary;
  padding: rem(10) rem(20);
  color: #fff;
  display: none;

  &__name {
    line-height: 1;

    &.has-drop-arrow {
      padding-right: 15px;
      position: relative;

      &:after {
        position: absolute;
        right: 0;
        @include chevron($color: $white, $direction: down, $size: 6px);
        top: 3px;
      }
    }
  }

  &__points {
    font-size: rem(18);
  }
}

.search {
  position: relative;
  margin-right: 10px;

  &__txt {
    display: none;
    position: absolute;
    right: 40px;
    border: 0;
    border-bottom: solid 1px $primary;
    width: 280px;
    height: 34px;
  }

  &__btn {
    width: rem(36);
    height: rem(36);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  &-icon {
    font-size: rem(18);
    line-height: 1;
    color: $textdark;
  }
}

.cargo-portal-container .header-comp .header-img-class-2 {
  height: 76px;
  background-color: #263A46;

  @media (min-width: 992px) {
    height: 72px;
  }
}

.cargo-portal-container {
  .header-navigation-l2-component-subhead {
    background-color: #2E4755;
  }

  .navigation-web-main-head>a {
    color: white !important;
    font-weight: 400!important;
    font-size: 14px !important;
  }

  .navigation-web-main-head.hover-out-gold:hover {
    border-bottom: 4px solid white !important;
    border-top: 4px solid transparent !important;
  }

  .navigation-web-main-head {
    height: 76px !important;
  }
}

.cargo-portal-container {
  .header-navigation-l2-component-item>a {
    color: white !important;
  }

  .category-title-header {
    color: white !important;
  }
}

.portal-header {
  .login-status-text{
    color: $white;
    font-size: 14px !important;
  }
  .login-section.login-text-section {
    .firstname-text {
      margin-top: 3px !important;
      padding-left: 5px;
      padding-top: 2px;
    }
  }

  .header-comp .header-primary .header-navbar-container .header-navbar-left .header-navbar-brand .header-text-logo .header-text-logo-web {
    min-height: 25px;
    max-height: 32px;
  }
  .header-comp .header-primary .header-navbar-container .header-navbar-left .header-navbar-brand .header-text-logo .header-text-logo-mobile {
    min-height: 25px;
    max-height: 32px;
  }

  .header-comp .header-primary .header-navbar-container {
    max-width: 1328px;
    height: 76px;

    @media (min-width: 992px) {
      height: 72px;
    }

    .header-navbar-right {
      height: 72px;

      @media (min-width: 992px) {
        height: 100px;
      }
    }

    .header-navitem-right-icons,
    .header-navbar-right {
      >li {
        height: 72px;

        @media (min-width: 992px) {
          height: 72px;
        }

        .login-icon-button {
          .login-section {
            margin-top: 0;
            white-space: nowrap;

            .firstname-initial {
              background-color: #435a69 !important;
            }

            .login-status-text.firstname-text {
              padding-left: 1rem;
              margin-left: 0;
              color: white;
            }

          }
        }
      }
    }
  }

  .loggedIn-widget-open {
    border-bottom: 4px solid $white;
    padding-top: 4px;
  }


  .login-icon-button {
    position: relative;

    .user-profile-menu {
      background: $white;
      box-shadow: 0 4px 8px -2px rgb(9 30 66 / 25%), 0 0 1px rgb(9 30 66 / 31%);
      position: fixed;
      right: 0;
      top: 75px;
      font-family: "EtihadAltis-Text", sans-serif;
      width: 100%;
      display: block !important;
      cursor: default;

      @media (min-width: 576px) {
        position: absolute;
        right: -13px;
        top: 54px;
        width: 365px;
      }

      @media (min-width: 992px) {
        top: 66px;
      }

      @media (min-width: 1200px) {
        top: 51px;
      }

      &__inner-wrapper {
        padding: 24px 24px 5px;
        position: relative;
        display: flex;
        margin-bottom: 15px;

        #welcome-wrapper h3 {
          font-family: $font-book;
          color: #4e4c4a;
          line-height: 30px;
          font-weight: 500px;
        }

        img {
          cursor: pointer;
        }
      }

      .user-name {
        font-size: 32px;
        font-family: $font-family;
        line-height: 40px;
        text-align: left;
      }

      .button-group {
        width: 100%;
        margin: 8px 12px 0;

        .primary-btn {
          color: #fff;
        }
      }

      .log-out-btn {
        .link {
          .icon {
            cursor: pointer;
            margin-left: 0;
            width: 20px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .p-overlaypanel-content {
        padding: 0;
      }

      .viewdash-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }

  .agent-code-header {
    float: right;
    margin: 10px 5px;
    font-size: 15px;
  }
}
.cargo-portal-container {
  .header-navigation-l2-component-container{
    top:71.5px;
    background-color: #2E4755;

  }
  .header-navigation-l2-component-subhead{
    justify-content: left !important;
    padding: 32px 20px;
    max-width: 1328px;
    margin: auto;
  }
  .header-comp .header-primary .header-navbar-container .header-navitem-right-icons > li.hover-out-gold:hover {
    border-bottom: 2px solid white;
  }
  .header-navigation-l2-component-item-con-wrapper {
    width: auto;
    max-width: 1328px;
    margin: 0 0 auto;
    padding: 0;
  }
  @media (min-width: 1368px){
    .header-navigation-l2-component-item-con-wrapper .category-wrapper{
      padding-left: 0;
    }
    .header-navigation-l2-component-subhead{
      padding-left: 0;
      padding-right:0;
    }
  }
  @media (min-width: 1200px){
    .header-comp .header-primary .header-navbar-container .header-navbar-left .header-navbar-brand .header-text-logo {
      margin: 0;
    }
  }
}
.cargo-portal-container .header-comp .header-primary .navbar-header{
  height: auto !important;
}
