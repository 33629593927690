@import "../../assets/scss/abstracts/abstracts";

.cargo-portal-footer {
  background-color: $etihadNewDarkBlue;
  display: flex;
  align-items: center;
  padding: 16px 0;
  line-height: 20px;
  margin-top: 40px;

  .footer-contents {
    max-width: 1328px;
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;

    .footer-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (min-width: 992px) {
        max-width: 67%;
      }
    }


    .left-top {
      align-items: flex-start;
    }

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      .right-block {
        order: -1;
        margin-bottom: 20px;
      }
      .footer-links {
        flex-direction: column;
      }
      .left-top {
        align-items: center;
      }
    }


    .left-block {

      a,
      p {
        color: $ethihadWhite;
        margin: 0;
        margin: 0px 0.25em;
      }
      .copyright-labeltext,
      .icplabel,
      .tc-labeltext {
        font-size: 14px;
        line-height: 18px;
        font-family: $font-book;
        font-weight:400;
        display: inline-block;
        @media (max-width: 992px) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }


      a:hover {
        text-decoration: underline;
      }

      .ellipse {
        width: 4px;
        height: 4px;
        margin: 0 1px;
      }
    }

    .right-block {
      .linkedin-icon {
        float: right;
      }
    }

    .icon-hover-magnify {
      text-decoration: none;
      transition: transform 0.3s ease;
    }

    .icon-hover-magnify:hover {
      transform: scale(1.2);
    }
  }
}