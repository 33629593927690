@import '../../../../assets/scss/abstracts/abstracts';

.capture-awb {

    .item-group {
        position: relative;
        box-shadow: 0px 1px 11px #00000029;
        padding: 12.5px 8px;
        margin-bottom: 11px;

        .grey-div {
            background: $lightbgColor;
            padding-left: 0;
        }

        .pieces-div {
            width: 12%;
            padding-left: 10px;
        }

        .close-button {
            height: 18px;
            float: right;
            margin: 5px;
            cursor: pointer;
            width: 13px;
        }

        .border-0 {
            border: 0px;
            box-shadow: none;
        }

        .borderRadius-R0 {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border-right: none !important;
        }

        .p-l-0 {
            padding-left: 0;
        }

        .p-t-0 {
            padding-top: 0;

        }

        .m-r-10 {
            margin-right: 10px;
        }

        .m-b-20 {
            margin-bottom: 0px;

            @media (min-width: 992px) {
                margin-bottom: 20px;
            }
        }

        .borderRadius-L0,
        .borderRadius-L0 .p-dropdown {
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
        }

        .margin-negativeLeft10 {
            margin-left: -10px
        }

        .border-right0 {
            border-right: 0
        }

        .border-left0,
        .border-left0 .p-dropdown {
            border-left: 0
        }
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-b-0 {
        margin-bottom: 0px;
    }

    .cust-dropdown {
        background: #EDEDEC !important;

        .p-dropdown-trigger .pi-chevron-down {
            background: #EDEDEC !important;
        }
    }


    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
        background: white
    }

    // .last-item{
    //     border-bottom: none;
    // }

    .p-dropdown {
        position: relative;

        .p-dropdown-trigger {
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translate(0, -50%);

            .pi-chevron-down {
                background: $white;
            }

            @media (min-width: 992px) {
                right: -5%;
            }
        }
    }

    .delete-section {
        height: 100%;
        margin-top: 3%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        span {
            font-size: rem(12)
        }
    }

    .no-wrap-type {
        white-space: nowrap;
        margin-right: 33px;
        padding-left: 3px;

        @media (min-width: 992px) {
            padding-left: 10px;
        }
    }

    .font-label-20 {
        font-size: 20px;
    }

    .m-t-1 {
        margin-top: 1px;
    }

    .capture-awb-uld-wrapper {
        .form-group input {
            font-size: 20px;
        }

        .add-overflow-link {
            color: #005f96;
            background: #fff;
            /* margin-top: 10px; */
            box-shadow: none;
            outline: none;
            padding: 20px 0;
            font-family: "EtihadAltis-Bold", sans-serif;
            font-size: 18px;
            border: none;
            box-shadow: none !important;
        }

        .overflow {
            padding-bottom: 15px;

            .button__another {
                width: 100%;

                @media (min-width:992px) {
                    width: auto;
                }

            }
        }

        .uld-form {
            .p-dropdown {
                .p-dropdown-trigger {
                    right: 0%;
                }
            }
        }

        .primary-button {
            margin: 5px 0 0 5px;
            .p-button-label {
                font-family: $font-bold;
            }

        }
    }

    .weight-div {
        .loose-weight {
            width: 40% !important;
        }
    }

    .form-group.pieces-div {
        width: 50%;
        padding-left: 5px;

        @media (min-width: 992px) {
            width: 12%;

        }
    }

    .capture-awb-loose-form {
        .form-group {
            .form-item__field {
                padding: 10px 15px;
            }
        }

        .primary-button {
            margin: 5px 0 0 5px;
            .p-button-label {
                font-family: $font-bold;
            }

        }
    }

    .warning-error {
        .p-inline-message {
            background: #e8f1f4;
            width: 100%;
            float: left;
            display: block;
            opacity: 1;
            font-size: 18px;
            color: #4e4c4a;
            padding: 9px;
        }

    }
}