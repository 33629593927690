@import "../../../assets/scss/abstracts/abstracts";
.schedule-visualization {
  .segments-page {
    font-family: "EtihadAltis-Book";
    .flights-segments {
      font-size: 20px;
      .airport-name {
        padding-top: 4px;
        padding-bottom: 14px;
        font-size: 24px;
        font-family: $font-family;
        font-weight: normal;
      }
      .segments-header {
        border-bottom: 1px solid $border;
        border-top: 1px solid $border;
        background-color: $white;
        top: 72px;
        margin-left: 0;
        margin-right:0;
        .col-1,
        .col-2 {
          padding-top: 24px;
          padding-bottom: 24px;
        }
        z-index: 1;
      }
      .flights-segment {
        border-bottom: 1px solid $border;
        margin-left: 0;
        margin-right:0;
        .col-1,
        .col-2 {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
      .col-1 {
        &.big-col {
          flex: 0 0 11.3333333333%;
          max-width: 11.3333333333%;
        }
      }
      .col-2 {
        &.small-col {
          flex: 0 0 13.6666666667%;
          max-width: 13.6666666667%;
        }
      }
      .col-1 {
        flex: 0 0 11.11111111111111%;
        max-width: 11.11111111111111%;
      }
      .col-freq {
        flex: 0 0 13.11111111111111%;
        max-width: 13.11111111111111%;
        .freq-day {
          display: inline-block;
          width: 12%;
          overflow: hidden;
        }
      }
      .col-via {
        flex: 0 0 9.11111111111111%;
        max-width: 9.11111111111111%;
      }
      .flights-outbound {
        .flight-outbound:last-child {
          border-bottom: 4px solid $border !important;
        }
      }
    }
  }
}
