@import "../../../assets/scss/abstracts/abstracts";


.form-group {
    line-height: 1.5;
    background-clip: padding-box;
    .cust-dropdown {
        background: #fff;
    }

    .form-item__field{
        border: 1px solid #4E4C4A;
        width:  100%;
        height: 45px;
        border-radius: 12px;
        cursor: pointer;
        padding-left: 0.75rem;
        padding-right: 2.5rem;
        background-repeat: no-repeat;
        background-position: center right 0.75rem;
        font-family: "EtihadAltis-Text", sans-serif;
        color: #4E4C4A;
        // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        box-shadow: none;
        &:focus {
          border: 1px solid #005fcc;
        }
        &__red-border{
          border: solid 1px $invalid;
           }
        
    }

    .form-control{
      &__red-border{
     border: solid 1px $invalid;
     border-radius: 12px;
      }
      // text-transform: uppercase !important;
   }
   
    .flight-search__segment-info{
        // padding: 0 10px;
        border: none;
        width: 50%;
        border-right: 1px dotted #000;
        display: flex;
        flex-direction: column;
        @include respond(md) {
          border: none;
          width: 100%;
        }
    }
}


.flight-search {

   margin: 5px 0 0 0;
   .other-value{
     padding-right: 59px;
   }
   &__flyingHr{
     opacity: 0.8;
   }
   &__flightNo{
    color: $black;
    font-size: $flightNoFont;
    width: 20%;
    font-family:$font-medium;
    letter-spacing: -0.07px;
text-transform: capitalize;
opacity: 0.8;
   }
   &__others{
    .chargeable-weight{
font-size: $stationFontSize;
  font-family:$font-medium;
  opacity: 0.8;
  color: $black;
  span{
    font-family:$font-family;
    font-size: $stationFontSize;
  }
    }
    .other-value{
      font-size: $controlFont;
      padding-bottom: 10px;
      color: #3B3B3A;
      opacity: 0.8;
      .time-field{
      font-family:$font-bold;
      color: #3B3B3A;
      margin-bottom: 0;
      opacity: 0.8;
      }
    }
   }
   &__routeDetails{
    display: block;
    margin-top: 0px;
    margin: 7px;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    strong{
      font-size: $controlFont;
      white-space: nowrap;
      letter-spacing: -0.09px;
    }
    span{
      font-size: $stationFontSize;
      color: $textcolor;
      letter-spacing: -0.08px;
      white-space: nowrap;
      @media (min-width: 768px) {
        opacity: 0.8;
      }
    }
    &:not(:first-child){
      margin-top: -12px !important;
    }
   }
   &__expandedDetails{
              margin: 7px;
              display: flex;
              justify-content: space-between;
              background-color: $lightbgColor !important;
              padding: 25px 12px;
              margin: 0;
              background: none;
              border: 0;
              @include respond(md) {
                display: block;
              }
              .flight-search__segment-info{
                  // padding: 0 10px;
                  border: none;
                  width: 50%;
                  border-right: 1px dotted $black;
                  display: flex;
                  flex-direction: column;
                  @include respond(md) {
                    border: none;
                    width: 100%;
                  }
              }
              
              .flight-search__routeDetails{
                display: flex;
                margin: 0px;
                //margin-bottom: 10px;
                background: none;
                align-items: center;
                align-items: flex-start;
                justify-content: stretch;
              }

              .flight-search__flyingHr{
                opacity: 0.8;
              }
              .flight-search__flightNo{
               color: $black;
               font-size: $flightNoFont;
               width: 20%;
               font-family:$font-medium;
               opacity: 0.8;
              }

              .flight-search__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                margin-right: 6px;
                margin-top: 3px;
                width: 80%;
                flex-direction: column;
                align-items: center;
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border: 1px solid #EDEDEC;
                  border-radius: 10px;
                  margin: 5px 0px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 3px 13px;
                  vertical-align: middle;
                  background: $white;
                }
                .segment-transit-line{
                  height: 13px;
                  width: 0;
                  border: none;
                  border-left: 1px solid $black;
                  vertical-align: middle;
                }
              }

              .flight-search__departure{
                display: inline-flex;
                flex-direction: column;
                line-height: 1;
              }

            }
              &__route{
              position: relative;
              margin: 0px 10px;
              border: none;
              width: 80%;
              justify-content: space-evenly;
            }
  &__allotment-wrapper{
    position: relative;
    margin: 0 17px;
  }
  &__allotment-description {
    margin-left: 20px;
    font: normal normal normal 15px/20px;
    color: $black;
  }
  &__allotment-indicator {
    position: absolute;
    height: 23.62px;
    width: 17px;
    background-color: $lightGreen;
    
    &::before {
      content: "A";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: $white;
      font: normal normal bold 12px/9px;
      
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-color: transparent;
      border-left: 8.5px solid transparent;
      border-right: 8.5px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 4px solid $white;
      
    }
  }
  &__item {
    display: flex;
    align-items: center;
    border: solid 1px $border;
    padding: 0.5rem rem(10);
    position: relative;
    flex-wrap: nowrap;
    margin-top: 7px;
    justify-content: space-between;
    box-shadow: 0px 3px 6px #2D1F5008;
    cursor: pointer;
    border-radius: 4px;
    .logo{
      width: 20%;
      img{
        margin-top: -7px;
        margin-left: 3px;
        height: 36px;
        width: 68px;
      }
    }
    @include respond(lg) {
      padding: rem(20);
      flex-direction: column;
    }
  }
        &__routes {
      text-align: center;
    display: block;
    padding: 0;
    // margin-bottom: -11px;
    margin-left: -7px;
    font-size: $stationFontSize;
    opacity: 0.8;
    }

  &__expanded{
    border: solid 1px $border;
    border-top:0;
    position: relative;
    flex-wrap: nowrap;
    
   
    @include respond(lg) {
      padding: rem(20);
      flex-direction: column;
    }

     &__timing {
    display: flex;
    min-width: 167px;
    padding-bottom: 0;
    justify-content: space-evenly;
    strong {
      font-size: $controlFont;
      @include respond(sm) {
        font-size: rem(18);
      }
    }
    span{
      font-size: $stationFontSize;
      padding: 1px 0px;
    }
  }

  }
  &__arrowUp{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    line-height: 1;
    cursor:pointer;
    i img{
      width: 18px;
    }
  }
  &__flyingHr{
    text-align: center;
    display: block;
    padding: 0;
  }
   &__layover{
                align-self: flex-end;
                text-align: center;
                display: flex;
                margin: 11px 0;
                margin-right: 6px;
                margin-top: 3px;
                width: 100%;
                flex-direction: column;
                align-items: center;
                &:before{
                  display: none;
                }
                &:after{
                  display: none;
                }
                span{
                  border: 1px solid #EDEDEC;
                  border-radius: 10px;
                  margin: 5px 0px;
                  display: inline-block;
                  font-size: $stationFontSize;
                  padding: 3px 13px;
                  vertical-align: middle;
                  background: $white;
                }
                .segment-transit-line{
                  height: 13px;
                  width: 0;
                  border: none;
                  border-left: 1px solid $black;
                  vertical-align: middle;
                }
              }
  &__timing {
    display: flex;
    min-width: 167px;
    padding-bottom: 0;
    justify-content: space-evenly;
    strong {
      font-size: $controlFont;
      @include respond(sm) {
        font-size: rem(18);
      }
    }
        span{
      font-size: $stationFontSize;
          padding: 1px 0px;
    }
  }
  &__departure {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 1;
    white-space: nowrap;
  }
  &__arrival {
    display: flex;
    flex-direction: column;
    line-height: 1;
    position: relative;
    white-space: nowrap;
  }
  .flight-search__expandedDetails{
   .flight-search__routeDetails{
    display: flex;
    margin-top: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
   }
  .flight-search__departure{
        display: inline-flex;
    flex-direction: column;
    line-height: 1;
      }

  }
   &__sub-connection{
     display: flex;
    flex-direction: row;
    align-items: center;
   }
  &__connection{
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 250px;
              
              align-content: space-between;
              align-items: center;
              margin: 0 7px;
              align-items: flex-start;
              flex-direction: row;
              padding-top: 3px;
              /* position: relative; */
              .origin-destination-icon{
                height: 8px;
                width: 8px;
                border: 1px solid $primary;
                border-radius: 50%;
                background-color: transparent;
              }
              .iconsSection{
                width: 14.12px;
                height: 8.26px;
                display: flex;
                align-items: center;
              }
              .transit-line{
                flex-grow: 1;
                height: 0;
                border: none;
                border-top: 1px dotted $primary;
                margin-top: 4px;
              }
            
      }
  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: rem(20) 0;
    width: 50%;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: rem(20) 0;
    width: 50%;
    &__service{
      width: 30%;
    }
    &__sub{
      width: 70%;
      display: flex;
      padding: 0 5px;
      align-items: center;
      justify-content: space-between;
      &__pricing{
        width: 50%;
        text-align: right;
        font-family: $font-medium;
        margin-right: 25px;
      }
      &__amount{
        line-height: 1;
        > label {
          font-size: $rateFont;
          position: relative;
          white-space: nowrap;
          color: $primary;
          margin-bottom: 0;     
          letter-spacing: -0.13px; 
          > em {
            position: absolute;
            font-size: rem(13);
            font-style: normal;
            top: -10px;
            right: 20px;
          }
        }
        span {
          color: $primary;
        }
      }
      &__rate{
        > label {
          position: relative;
          margin-bottom: 0;
          font-size: $controlFont;
          color: $textcolor;
          letter-spacing: -0.09px;
        }
      }
    }
  }
  &__elapse{
    text-align: center;
    display: block;
    padding: 0;
    margin-top: -25px;
    font-size: $stationFontSize;
    color: $textcolor;
    opacity: 0.8;
  }
 
  .fd-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    &__right {
      display: inline-flex;
      align-items: center;
    }
    &__left {
      display: inline-flex;
    }
    &__results{
      font-size:$subTitleFont;
      font-family: $font-bold;
       margin: auto;
      color: $titleColor;
      @include respond(sm) {
        margin-bottom: 10px;
      }
    }
    &__sort {
      color: $textcolor;
      margin-left: rem(20);
      &.has-drop-arrow {
        padding-right: 15px;
        position: relative;
        &:after {
          position: absolute;
          right: 0;
          @include chevron($color: $textcolor, $direction: down, $size: 7px);
          top: 5px;
        }
      }
      > label{
        color: $grey;
        margin-right: 5px;
      }
    }
    &__tab {
      display: flex;
      border: solid 1px $border;
      border-radius: 4px;
      overflow: hidden;
      a {
        padding: rem(8) rem(15);
        display: inline-block;
        &:hover {
          background: $textcolor;
          color: $white;
        }
        &.active{
          background: $textcolor;
          color: $white;
        }
      }
      li + li {
        border-left: solid 1px $border;
      }
    }
  }

}
.borderB0{
      border-bottom:0 !important;
    }
    .borderT0{
      border-top:0 !important;
    }
    .border-expand{
      border: 0;
    }
     .no-data{
    
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid $border;
    padding: 9px 10px;
    margin: 25px 0;
    font-family: $font-medium;
    box-shadow: 0px 3px 6px #2D1F5008;
    font-size: 14px;
    justify-content: flex-start;
    @media (min-width: 992px) {
      margin: 25px 20px;
    }   

  }


  .flight-enquiry-form-panel {
    padding: 30px 4px;
    border-radius: 14px;
    margin: 0px 0px 10px 0px;
    @media (min-width: 992px) { 
        padding: 30px 35px;
        border-radius: 14px;
        box-shadow: 0px 3px 6px #00000029;
     }
    .form-group .form-item__field{
      height: 50px;
      font-size: 20px;
      font-family: $font-book;
      border-radius: 12px;
      .flightNumber_input_box{
        align-items: center;
        justify-content: center;
        width:  66%;
        height: 50px;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 15px;
        color: #4E4C4A !important;
        display: flex;
        border-radius: 12px;
      }
    }
    .ey_input_box{
      display: flex;
      align-items: center;
      justify-content: center;
      width:  34%;
      height: 100%;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 15px;
      color: #4E4C4A !important;
      #carrierCode{
        padding-right:0px;
      }
    }
    .form-item__label{
        @media (min-width: 992px) {     
            padding-top: 3px;                 
        }
    }
    @media (min-width: 992px) {
        .col-md-auto{
            padding-right: 50px;
            margin-bottom: 0;               
        }
    }   
    .textFiledCaps{
      text-transform: uppercase !important;
      font-size: 20px;
      font-family: $font-book;
    }     
}
#flightType, #aircraftClassification{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxbDYgNiA2LTYiIHN0cm9rZT0iIzRFNEM0QSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
          -webkit-appearance: none;
          appearance: none;
}
.flight-result{
  font-size:20px;
  font-family:$font-medium;
   @media (min-width: 992px) {     
                font-size: 24px;
                font-family:$font-medium;                
            }
}