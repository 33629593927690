.seasonal-booking {
    .flex-container {
        display: flex;
        flex-wrap: nowrap;
        // background-color: #f1f1f1;
    }

    .flex-container .weekdays-scheduler {

        //margin: 0px 12px 0px 0px;
        margin: 8px 8px 0px 0px;
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        width: 40px;
        color: rgb(78, 76, 74);
        font-family: EtihadAltis-Bold;
        border-radius: 4px;
        border: 0.5px solid rgb(78, 76, 74);
        height: 40px;
        width: 40px;
        background-color: #fff;


    }

    .weekdays-scheduler:is(:hover) {
        cursor: pointer;
    }

    .weekdays-scheduler:is( :focus) {
        background-color: rgb(38, 35, 100);
        cursor: pointer;
        color: rgb(255, 255, 255);
        font-family: EtihadAltis-Bold;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        text-align: center;
        border-radius: 4px;
        border: 0.5px solid rgb(78, 76, 74);
        height: 40px;
        width: 40px;
    }

    .label-onday {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 6px !important;
        font-weight: 400 !important;
        margin-left: 0px;

    }

    .form-item__label {
        margin-left: 0px;
    }

    .form-item--select {
        .form-item__field {
            height: 50px;
            border: solid 1px #4E4C4A;
            color: #4D4F5C;
            font-size: 18px;
            cursor: pointer;
            padding-left: 0.75rem;
            padding-right: 2.5rem;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxbDYgNiA2LTYiIHN0cm9rZT0iIzRFNEM0QSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
            background-repeat: no-repeat;
            background-position: center right 0.75rem;
            -webkit-appearance: none;
            appearance: none;
            margin: 0px;
            border-radius: 12px;
        }
    }

}