@import '../../assets/scss/abstracts/abstracts';

.price-calendar{
  display: flex;
  &__details{
    // margin: 10px auto;
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 5px 16px 5px 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    @media (min-width: 992px) {
      padding: 5px 16px 11px 16px;
      flex-wrap: wrap;
      white-space: normal;
      overflow:hidden;  
      overflow-x: hidden;    
    }
}
&__cheepest{
font-size: 14px;
 color:#4e4c4a;
 font-family: $font-book;
 @media (min-width: 992px) {
   font-family: $font-family;

 }
}
&__date{
  font-size: $linkFont;
  text-align: center;
  margin-bottom: 7px;
  margin-top: 20px;
  color: $titleColor;
}
&__item{
    background: #ffffff;
    opacity: 1;
    // padding: 15px 62.5px;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    @media (min-width: 992px) {
      border-radius: 5px;
      border:1px solid #707070;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
    }  
}
&__nodata{
      font-size: 14px;
      word-break: break-word;
      text-align: center;
}
&__price{
    margin-bottom: -2px;
    position: relative;
    font-size: 16px;
    text-align: center;
    font-family: $font-book;
    @media (min-width: 992px) {
      font-size: $linkFont;
      font-family: $font-medium;
    }
        > em{
      position: absolute;
      // right: 0;
      font-size: rem(10);
      top: -10px;
      font-style: normal;
    } 
}
&__rate{
    margin-bottom: -2px;
    position: relative;
    color:$primary;
    font-size: 16px;
    font-family: $font-medium;
    @media (min-width: 992px) {
      font-size: $subTitleFont;
    }
}
  
  &__label{
    font-size: 14px;
    text-align: center;
    color: $black;
    font-family: $font-book;
    
  }
  .col-half-offset{
    flex: 1;
    word-break: break-all;
    font-size: 1.5rem;
    cursor:pointer;
    margin: 0 5px;
    min-width: 130px;
    margin-bottom: 11px;
    @media (min-width: 992px) {
      min-width: 1px; 
      margin-bottom: 0;
    }    
}
.col-half-offset:last-child{
  border-right:  0;
}

&__nav-btn {
  position: relative;
  min-width: 36px; 
  display: none;
  @media (min-width: 992px) {
    display: block;
    // border-bottom: 1px solid #005f96;  
  }    
  button{
    position: absolute;
    height: 36px;
    width: 36px;
    //border-radius: 36px;
    padding: 4px;
    box-sizing: content-box;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    outline: none;
    img {
      border: none;
      outline: none;
    }
  }
}

  &__item.active {
    background: $etihadNewDarkBlue;
    color: white;
    border:none;
    position: relative;
       &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid $etihadNewDarkBlue;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    bottom: -10px;
}


    .price-calendar__price,.price-calendar__rate{
    color:white ;
    }
    .price-calendar__label,.price-calendar__cheepest{
      color:white;
      font-family: normal normal 10px/13px Etihad Altis;
    }
    .price-calendar__label{
 font-size: 14px;
 font-family: $font-family;
    }
    &:hover{
      background: $etihadNewDarkBlue;
      color:white;
    }
  }



.price-calendar__dates
{.price-calendar__item{
 border: 0;
    font-size: 15px;
    padding: 12px 48px 0px 48px;

  }
}
}

.loading-icon{
  font-size: 2rem;
}

.no-click{
  cursor: not-allowed !important;
}

