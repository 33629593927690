@import '../../assets/scss/abstracts/abstracts';

.cargo-portal-container.portal-tabs {
    background-color: #fff;
    padding: 0px 15px 30px;
    margin-top: 51px;
    .p-component {
        font-family: inherit;
        #loose-portal{
            font-family:$font-bold;
        }
    }
    .p-tabview {
        .p-tabview-nav { 
            margin-bottom: 0;
            border:none;
           
            li.p-highlight {
                .p-tabview-nav-link {
                    background: #fff;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: $tabLabelSelected;
                    border-bottom: 5px solid $tabSelectedBorder;
                    font-family:$font-medium ;
                    font-weight: medium;
                }
            }
            li { 
                .p-tabview-nav-link {
                    background: #fff;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: $tabLabelUnSelected;
                    border: none;
                    padding: 25px 25px 26px 16px;                  
                    text-decoration: none;  
                    font-family:$font-medium ;
                    font-weight: medium;   
                    &:first-child {
                        padding-left: 0;
                        padding-right: 0;
                        margin-right: 25px;
                        @media (min-width: 992px) { 
                            padding-left: 16px;
                            padding-right: 25px;
                            margin-right: 0;
                        }
                    }              
                    &::before {
                        @media (min-width: 992px) { 
                            content: "";
                            width: 25px;
                            height: 20px;
                            background-color: transparent;
                            background-image: url('../../assets/images/List-view.png');
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-right: 4px;
                         }
                    }
                }
                &:not(.p-highlight):not(.p-disabled):hover {
                    .p-tabview-nav-link {
                        border-bottom: 5px solid $tabSelectedBorder;
                    }
                }
                .p-tabview-nav-link:not(.p-disabled):focus {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    outline: none!important;
                }
                &.my-profile-tab {
                    .p-tabview-nav-link {
                        &::before {
                            background-image: url('../../assets/images/icon-account.svg');
                        }
                    }
                    
                }
                &.no-icon {
                    .p-tabview-nav-link {
                        font-family: $font-book;
                        font-weight: normal;
                        font-size: 16px;
                        padding: 17px 16px 17px 16px;
                        &::before {
                            content: none;
                            background-image: none;
                        }
                    }
                    &.p-tabview-selected {
                        .p-tabview-nav-link  {
                        font-family: $font-medium;
                        }
                    }
                }
            }
            .sub-user-tab{
                display:none;
            }
        }
        .p-tabview-panels {
            border: none;
            border-top: 1px solid #C7C6C6;
            padding: 0;
        }       
    }
    .user-info {
        
        @media (min-width: 992px) { 
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 14px;
            padding: 18px 22px 50px 40px;
        }
    }
    .notifications-info {
        border-radius: 14px;
        margin-left: 2px;
        margin-right: 0px;
        @media (min-width: 992px) {
            margin-left: 0;
            padding: 30px 35px 6px 35px;
            box-shadow: 0px 3px 6px #00000029;
        }
        .form-item__label{
            font-family: $font-book;
            @media (min-width: 992px) {     
                font-size: 20px;    
                padding-top: 3px;                 
            }
        }
        .col-md-auto{
            padding-right: 1rem;
            @media (min-width: 992px) {
                padding-right: 50px;
                margin-bottom: 24px;               
            }
        }        
    }
    .welcome-head-wrapper{
        font-size: 32px;
        font-family: $font-book;
    }
    .welcome-sub-head-wrapper{
        font-size: 18px;
        font-family: $font-book;
    }
    .user-privileges{
        &.add-account {
            .previlege_heading {
                font-family: "EtihadAltis-Text", sans-serif;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .form-item__label{
            @media (min-width: 992px) {     
                font-size: 20px;                 
            }
        }
        .col-md-auto{
            padding-right: 35px;
        }
    }
    .portal-popup-modal{
        #button-first-ok{
            font-family:$font-bold;
        }
        #button-first-cancel{
            font-family:$font-bold;
            .p-button-label {
                position:relative;
                right:2px;
            }
        }
    }
    .red-border .form-item__field {
        box-shadow: 0 0 5px #cb2c30;
        border: 1px solid #cb2c30;
      } 
}